import { AGENTE_PENITENCIARIO_LISTAR } from '../actions';

const INITIAL_STATE = [];

export default function agentePenitenciarioListarReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AGENTE_PENITENCIARIO_LISTAR:
            return action.agentesPenitenciarios;
		default:
			return state;
	}
}