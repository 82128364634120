import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import EspecialidadeModel from '../Model/especialidadeModel.js';
import { getIdToken, request } from '.';
import { replaceEspaceFieldsInObject } from '../utils/utils.js';

//Muda um campo especifico do especialidade atual na redux
export const ESPECIALIDADE_SET_FIELD = 'ESPECIALIDE_SET_FIELD';
export const especialidadeSetField = (field, value) => {
    return {
        type: ESPECIALIDADE_SET_FIELD,
        field,
        value,
    }
}
//Muda o especialidade atual na redux
export const ESPECIALIDADE_SET_CURRENT = 'ESPECIALIDADE_SET_CURRENT';
export const especialidadeSetCurrent = especialidade => ({
    type: ESPECIALIDADE_SET_CURRENT,
    especialidade
});

//Para não fazer uma nova requisição é chamada essa Function que pega a especialidade desejado na lista da Redux
export const getEspecialidade = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade vazia
            const especialidade = new EspecialidadeModel().voidConstructor();
            resolve(especialidade);
        } else {
            var currentValue = store.getState().especialidadesList;
            console.log(currentValue)
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade
                    const especialidade = new EspecialidadeModel().completeConstructor(item);
                    resolve(especialidade);
                }
            });
        }
    });
}


//Faz a requisição para atualizar a variavel ATIVO do especialidade para True or False;
export const especialidadeSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var especialidade = await getEspecialidade(id);
        especialidade.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/especialidade/${id}`,
                especialidade,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//Envia o especialidade para tela de Create ou Update 
export const especialidadeSetAtual = (id) => async dispatch => {
    if (id == null) {
        var especialidade = new EspecialidadeModel().voidConstructor();
        dispatch(especialidadeSetCurrent(especialidade));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/especialidade/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const especialidade = new EspecialidadeModel().completeConstructor(resp.data.data);
                dispatch(especialidadeSetCurrent(especialidade));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar o especialidade
export const especialidadeAtualizar = async (idEspecialidade) => {
    //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade para atualizar
    var especialidadeModel = replaceEspaceFieldsInObject (new EspecialidadeModel().completeConstructor(store.getState().especialidade));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/especialidade/${especialidadeModel.id}`,
                //Body
                especialidadeModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//Faz a requisição para cadastrar o especialidade
export const especialidadeCriar = async () => {
    //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade para cadastrar um novo especialidade
    const especialidadeModel = replaceEspaceFieldsInObject (new EspecialidadeModel().completeConstructor(store.getState().especialidade));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                "/especialidade/",
                //Body
                especialidadeModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//########################LISTAGEM!################################################################################
export const ESPECIALIDADE_LISTAR = 'ESPECIALIDADE_LISTAR';
const especialidadeListar = (especialidade) => ({
    type: ESPECIALIDADE_LISTAR,
    especialidades: especialidade,
});

//Chama a requisição para listar todos os especialidades
export const especialidadesLista = (callback) => {
    return async dispatch => {
        const arrEspecialidades = [];
        var idToken = await getIdToken();
        request
            .get("/especialidades", {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(especialidade => {
                    //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade
                    let especialidadeModel = new EspecialidadeModel().completeConstructor(especialidade)
                    arrEspecialidades.push(especialidadeModel);
                })
                //Envia o array de especialidades para a redux
                callback(arrEspecialidades);
                return dispatch(especialidadeListar(arrEspecialidades));
            }).catch(error => {
                console.log(error)
            });
    }
};

//Chama a requisição para listar todos os especialidades e converte para encaixar em um elemento de select
export const especialidadesListaToSelect = () => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get("/especialidades",
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                resp.data.data.map(especialidade => {
                    //Faz a chamada do model especialidade e chama o metodo que constrói a Classe de especialidade
                    let tt = {
                        value: especialidade.id,
                        label: especialidade.nome,
                        color: "#00B8D9",
                    }
                    item.push(tt);;
                })
                //Envia o array de especialidades para a redux
                dispatch(especialidadeListar(item));
            }).catch(error => {
                console.log(error)
            });
    }
}
export default especialidadeAtualizar