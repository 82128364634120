import {
    PRESCRICAO_SET_FIELD,
    PRESCRICAO_SET_CURRENT
} from '../actions';
import PrescricaoModel from '../Model/prescricaoModel';

const INITIAL_STATE_PARCEIRO = new PrescricaoModel().voidConstructor();

export default function convenioReducer(state = INITIAL_STATE_PARCEIRO, action) {
    switch (action.type) {
         case PRESCRICAO_SET_CURRENT:
            return action.prescricao;
        case PRESCRICAO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}