import { getColor } from "../utils/foto";

class TecEnfermagemModel {
    constructor() {

    }

    voidConstructor() {
        let tecEnfermeiroModel = {
            id: '',
            ativo: true,
            clinicaDigitalNome: "",
            coren: "",
            password: "",
            cpf: "",
            dataNasc: "",
            email: "",
            fotoPerfil: "",
            idClinicaDigital: "",
            idAtendimento: "",
            idParceiro: "",
            idPaciente: "",
            nome: "",
            parceiroNome: "",
            rg: "",
            sexo: "",
            uf: "",
            statusTecEnfermagem: 0,
            color: getColor()

        }
        return tecEnfermeiroModel;
    }

    completeConstructor(tecEnfermeiro) {
        let tecEnfermeiroModel = {
            id: tecEnfermeiro.id,
            ativo: tecEnfermeiro.ativo,
            clinicaDigitalNome: tecEnfermeiro.clinicaDigitalNome,
            coren: tecEnfermeiro.coren,
            cpf: tecEnfermeiro.cpf,
            dataNasc: tecEnfermeiro.dataNasc,
            email: tecEnfermeiro.email,
            fotoPerfil: tecEnfermeiro.fotoPerfil,
            idClinicaDigital: tecEnfermeiro.idClinicaDigital,
            idAtendimento: tecEnfermeiro.idAtendimento,
            idParceiro: tecEnfermeiro.idParceiro,
            idPaciente: tecEnfermeiro.idPaciente,
            nome: tecEnfermeiro.nome,
            parceiroNome: tecEnfermeiro.parceiroNome,
            rg: tecEnfermeiro.rg ?? "",
            sexo: tecEnfermeiro.sexo,
            uf: tecEnfermeiro.uf,
            statusTecEnfermagem: tecEnfermeiro.statusTecEnfermagem,
            color: tecEnfermeiro.color
        }
        return tecEnfermeiroModel;
    }

    createConstructor(tecEnfermeiro) {
        let tecEnfermeiroModel = {
            ativo: tecEnfermeiro.ativo,
            clinicaDigitalNome: tecEnfermeiro.clinicaDigitalNome,
            coren: tecEnfermeiro.coren,
            cpf: tecEnfermeiro.cpf,
            dataNasc: tecEnfermeiro.dataNasc,
            email: tecEnfermeiro.email,
            fotoPerfil: tecEnfermeiro.fotoPerfil,
            idClinicaDigital: tecEnfermeiro.idClinicaDigital,
            idAtendimento: tecEnfermeiro.idAtendimento,
            idParceiro: tecEnfermeiro.idParceiro,
            idPaciente: tecEnfermeiro.idPaciente,
            nome: tecEnfermeiro.nome,
            parceiroNome: tecEnfermeiro.parceiroNome,
            rg: tecEnfermeiro.rg,
            sexo: tecEnfermeiro.sexo,
            uf: tecEnfermeiro.uf,
            password: tecEnfermeiro.password,
            statusTecEnfermagem: tecEnfermeiro.statusTecEnfermagem,
            color: tecEnfermeiro.color,
            uf: tecEnfermeiro.uf,
        }
        return tecEnfermeiroModel;
    }

}
export default TecEnfermagemModel;