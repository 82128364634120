import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import OcorrenciaModel from '../Model/prescricaoModel.js';
import { getIdToken, request } from '.';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';

//Muda um campo especifico do ocorrencia atual na redux
export const OCORRENCIA_SET_FIELD = 'OCORRENCIA_SET_FIELD';
export const ocorrenciaSetField = (field, value) => {
    return {
        type: OCORRENCIA_SET_FIELD,
        field,
        value,
    }
}
//Muda a ocorrência atual na redux
export const OCORRENCIA_SET_CURRENT = 'OCORRENCIA_SET_CURRENT';
const ocorrenciaSetCurrent = ocorrencia => ({
    type: OCORRENCIA_SET_CURRENT,
    ocorrencia
});

//Para não fazer uma nova requisição é chamada essa Function que pega o Antecedente desejado na lista da Redux
export const getOcorrencia = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Ocorrencia e chama o metodo que constrói a Classe de Ocorrencia vazia
            const ocorrencia = new OcorrenciaModel().voidConstructor();
            resolve(ocorrencia);
        } else {
            var currentValue = store.getState().ocorrencias;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Ocorrencia e chama o metodo que constrói a Classe de ocorrencia
                    const ocorrencia = new OcorrenciaModel().completeConstructor(item);
                    resolve(ocorrencia);
                }
            });
        }
    });
}

//Faz a requisição para atualizar a variavel ATIVO do antecedente para True or False;
export const ocorrenciaSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var ocorrencia;
        await getOcorrencia(id).then(resp => {
            ocorrencia = resp;
        });
        //Se ele estiver Ativo passara a ser Falso e vice-versa
        ocorrencia.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/ocorrencia/${id}`,
                //Body
                ocorrencia,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject()
            });
        } catch (error) {
            reject()
        }
    });
}
//Envia a Ocorrencia para tela de Create ou Update 
export const ocorrenciaSetAtual = (id) => async dispatch => {
    if (id == null) {
        const ocorrencia = new OcorrenciaModel("", "", true, "");
        dispatch(ocorrenciaSetCurrent(ocorrencia));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/ocorrencia/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const ocorrencia = new OcorrenciaModel(resp.data.data.titulo, resp.data.data.descricao, resp.data.data.ativo, resp.data.data.id);
                dispatch(ocorrenciaSetCurrent(ocorrencia));
            });
    }
}
//Faz a requisição para atualizar o antecedente
export const ocorrenciaAtualizar = async () => {
    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
    var ocorrencia = new OcorrenciaModel().completeConstructor(store.getState().ocorrencia);
    ocorrencia = replaceEspaceFieldsInObject (ocorrencia);
    ocorrencia.titulo.trim();
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/ocorrencia/${ocorrencia.id}`,
                //Body
                ocorrencia,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error);
        }
    })
}

//Faz a requisição para cadastrar a ocorrência
export const ocorrenciaCriar = async () => {
    //Faz a chamada do model Ocorrência e chama o metodo que constrói a Classe de ocorrência para cadastrar um novo ocorrência
    const ocorrencia = replaceEspaceFieldsInObject(new OcorrenciaModel().createConstructor(store.getState().ocorrencia));
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                "/ocorrencia/",
                //Body
                ocorrencia,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}


export const OCORRENCIA_LISTAR = 'OCORRENCIA_LISTAR';
const ocorrenciaListar = (ocorrencia) => ({
    type: OCORRENCIA_LISTAR,
    ocorrencias: ocorrencia,
});

//Chama a requisição para listar todas Ocorrências
export const ocorrenciasLista = (callback) => {
    return async dispatch => {
        const arrOcorrencia = [];
        var idToken = await getIdToken();
        request
            .get("/ocorrencias", {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                resp.data.data.map(ocorrencia => {
                    //Faz a chamada do model Ocorrência e chama o metodo que constrói a Classe de Ocorrência
                    let ocorrenciaModel = new OcorrenciaModel().completeConstructor(ocorrencia);
                    arrOcorrencia.push(ocorrenciaModel);
                }
                )
                //Envia o array de ocorrências para a redux
                callback(arrOcorrencia);
                return dispatch(ocorrenciaListar(arrOcorrencia));
            }).catch(error => {
                console.log(error)
            });
    }
}