import {
    HORARIO_SET_FIELD,
    HORARIO_SET_CURRENT
} from '../actions';
import Horario from '../Model/HorarioAgendaMedico';

const INITIAL_STATE_HORARIO = new Horario().voidConstructor();

export default function horarioReducer(state = INITIAL_STATE_HORARIO, action) {
    switch (action.type) {
         case  HORARIO_SET_CURRENT:
            return action.horario;
        case HORARIO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}