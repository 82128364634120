export class Sound {
    constructor(nomeElement, src) {
        this.nomeElement = nomeElement;
        this.src = src;
    }
    nomeElement;
    src;
    element;

    createElement() {
        try {
            this.element = document.createElement("audio");
            this.element.setAttribute("type", "audio/mpeg");
            this.element.setAttribute("id", this.nomeElement);
            this.element.setAttribute("src", this.src);
            this.element.setAttribute("autoplay", true);

        } catch (error) {
            console.log(error)
        }
    }

    getElement() {
        return this.element;
    }

    play() {
        try {
            this.element.play();
        } catch (error) {
            console.log(error)
        }

    }

    pause() {

    }

}