import {
    MEDICO_SET_FIELD,MEDICO_SET_CURRENT
} from '../actions';
import MedicoModel from '../Model/medicoModel';

const INITIAL_STATE = new MedicoModel().voidConstructor();

export default function medicoReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MEDICO_SET_CURRENT:
            return action.medico;
        case MEDICO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}