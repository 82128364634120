import { ENFERMEIRO_SET_FIELD, 
    ENFERMEIRO_SET_CURRENT } from '../actions';
import EnfermeiroModel from '../Model/enfermeiroModel';

const INITIAL_STATE = new EnfermeiroModel().voidConstructor();

export default function enfermeiroReducer(state = INITIAL_STATE, action) {
switch (action.type) {
   case ENFERMEIRO_SET_CURRENT:
       return action.enfermeiro;
   case ENFERMEIRO_SET_FIELD:
       const newState = { ...state };
       newState[action.field] = action.value;
       return newState;
   default:
       return state;
}
}