export function getFoto(foto) {
    if (foto !== '' && foto !== undefined) return foto;
    return false;
}

export function getColor() {
    var RGB = [];
    var color;
    while (RGB.length < 3) {
        var color = (Math.floor(Math.random() * 256));
        for(var i = 0; i < RGB.length; i++ ){
            if(RGB[i] > 200){
                color = (Math.floor(Math.random() * 201))
            }
        }
        RGB.push(color)
    }
    color = "rgb"+"(" + RGB[0] + "," +  RGB[1] + "," + RGB[1]+")"
    return color;
}
export function getFirstLetter(nome){
    if(nome != undefined){
       return nome[0]
    }
}