import firebase from "../config/firebase";
import Log from '../utils/log'
import "firebase/auth";
import "firebase/firestore";
import { store } from "../index.js";
import { collectionData, docData } from "rxfire/firestore";
import Cripto from '../utils/cripto'
import { getIdToken, request } from ".";
import { replaceEspace } from "../utils/utils";
import { Sound } from "../Model/Sound";

const db = firebase.firestore();

export const ATENDIMENTO_SET_FIELD = 'ATENDIMENTO_SET_FIELD';
export const atendimentoSetField = (field, value) => {
  return {
    type: ATENDIMENTO_SET_FIELD,
    field,
    value,
  }
}

export const STATUS_ENFERMEIRO = "STATUS_ENFERMEIRO";
const statusEnfermeiro = (statusEnfermeiro) => ({
  type: STATUS_ENFERMEIRO,
  statusEnfermeiro: statusEnfermeiro,
});

export const ATENDIMENTO_SET_CURRENT = 'ATENDIMENTO_SET_CURRENT';
export const atendimentoSetCurrent = atendimento => ({
  type: ATENDIMENTO_SET_CURRENT,
  atendimento
});

export const TRIAGEM_SET_CURRENT_ID = 'TRIAGEM_SET_CURRENT_ID';
export const triagemSetCurrent = triagem => ({
  type: TRIAGEM_SET_CURRENT_ID,
  triagem
});

export const TRIAGENS_LISTAR = "TRIAGENS_LISTAR";
const triagensListar = (triagem) => ({
  type: TRIAGENS_LISTAR,
  triagens: triagem,
});

export const TRIAGENS_TOTAL = "TRIAGENS_TOTAL";
const triagemTotal = (
  totalTriagensCollection,
  statusTriagem,
  totalTriagemRealizadas,
  triagem_current_id
) => ({
  type: TRIAGENS_TOTAL,
  totalTriagensCollection: totalTriagensCollection,
  totalTriagemRealizadas: totalTriagemRealizadas,
  statusTriagem: statusTriagem,
  triagem_current_id: triagem_current_id,
});

export const TRIAGENS_TOTAL_COLLECTION = "TRIAGENS_TOTAL_COLLECTION";
const triagemTotalCollection = (totalTriagensCollection) => ({
  type: TRIAGENS_TOTAL_COLLECTION,
  totalTriagensCollection: totalTriagensCollection,
});

export const TRIAGENS_TOTAL_ATENDIDOS = "TRIAGENS_TOTAL_ATENDIDOS";
const triagemTotalAtendidos = (totalTriagensRealizados) => ({
  type: TRIAGENS_TOTAL_ATENDIDOS,
  totalTriagensRealizados: totalTriagensRealizados,
});

export const TRIAGENS_SET_CURRENT_ID = "TRIAGENS_SET_CURRENT_ID";
export const triagensSetCurrentId = (triagem_current_id) => ({
  type: TRIAGENS_SET_CURRENT_ID,
  triagem_current_id: triagem_current_id,
});
export const TRIAGEM_COUNT = "TRIAGEM_COUNT";
export const triagemCount = (triagem_count) => ({
  type: TRIAGEM_COUNT,
  triagem_count: triagem_count,
});
export const TRIAGEM_TOTAL_FILA = "TRIAGEM_TOTAL_FILA";
const triagemTotalFila = (triagem_totalFila) => ({
  type: TRIAGEM_TOTAL_FILA,
  triagem_totalFila: triagem_totalFila,
});
export const TRIAGEM_TEMPOS = "TRIAGEM_TEMPOS";
const triagemTempos = (objTempos) => ({
  type: TRIAGEM_TEMPOS,
  tempoEsperaFila: objTempos.tempoEsperaFila,
  tempoTriagem: objTempos.tempoTriagem,
});

export const TRIAGEM_TEMPOS_MEDIOS = "TRIAGEM_TEMPOS_MEDIOS";
const triagemTemposMedios = (objTempos) => ({
  type: TRIAGEM_TEMPOS_MEDIOS,
  tempoMedioTriagem: objTempos.tempoMedioTriagem,
  tempoMedioEspera: objTempos.tempoMedioEspera,
  tempoMedioTotalServico: objTempos.tempoMedioTotalServico,
});

export const ATENDIMENTOS_LISTAR = "ATENDIMENTOS_LISTAR";
const atendimentosListar = (atendimento) => ({
  type: ATENDIMENTOS_LISTAR,
  atendimentos: atendimento,
});

export const TRIAGEM_DOCUMENTOS = "TRIAGEM_DOCUMENTOS";
const triagensDocumentos = (documentos) => ({
  type: TRIAGEM_DOCUMENTOS,
  documentos: documentos,
});

export const triagensListaRelatorio = () => {
  try {
    return async (dispatch) => {
      var id = Cripto.DecryptedStorage('uid');

      var snapshot = await firebase
        .firestore()
        .collection("enfermeiros")
        .doc(id)
        .collection("atendimentos")
        .orderBy("dataIniTriagem", "desc")
        .get();
      var item = [];
      snapshot.docs.map((doc) => {
        let atendimento = { ...doc.data(), id: doc.id };
        item.push(atendimento);
      });
      await dispatch(atendimentosListar(item));
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentosLista', ex.message, "")
  }
};

export const triagensLista = () => {
  try {
    return async (dispatch) => {
      var id = Cripto.DecryptedStorage('uid');

      var snapshot = await firebase
        .firestore()
        .collection("enfermeiros")
        .doc(id)
        .collection("atendimentos")
        .orderBy("dataIniTriagem", "desc")
        .limit(5)
        .get();
      var item = [];
      snapshot.docs.map((doc) => {
        let atendimento = { ...doc.data(), id: doc.id };
        item.push(atendimento);
      });
      await dispatch(atendimentosListar(item));
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentosLista', ex.message, "")
  }
};

export const enfermeiroFinalizaCallSincrona = async () => {
  try {
    return await new Promise(async (resolve, reject) => {
      var idEnfermeiro = Cripto.DecryptedStorage('uid');
      var atendimentoId = Cripto.DecryptedStorage('triagem_atual');
      const pacienteId = store.getState().atendimento.idPaciente;
      var idToken = await getIdToken();
      request.post(`/enfermeiro/${idEnfermeiro}/atendimento/${atendimentoId}/paciente/${pacienteId}/finalizarvideo`, {}, {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      })
        .then((response) => {
          if (response.data.statusOk) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((e) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona1', e.message, "")
          return false;
        });
    })
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona2', ex.message, "")
    return false;
  }
};

export const salvarAnamneseTriagem = async () => {
  try {
    const atendimento = store.getState().atendimento;
    const id = Cripto.DecryptedStorage('uid');
    var idToken = await getIdToken();
    var text = replaceEspace(atendimento.anamneseTriagem)
    return await new Promise((resolve, reject) => {
      request.patch(`/enfermeiro/${id}/atendimento/${atendimento.idAtendimento}/updateanamnese`,
        {
          idPaciente: atendimento.idPaciente,
          texto: text,
          gravou: true,
        },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      ).then((result) => {
        if (result.data.statusOk) {
          resolve(true);
        } else {
          reject(result.data.message)
        }
      }).catch((error) => {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarAnamnese1', error, "")
        reject()
      });
    });
  }
  catch (error) {
    console.log(error)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarAnamnese2', error, "")
  }
};

export const setSinaisVitais = async () => {
  return await new Promise(async (resolve, reject) => {
    try {
      var idToken = await getIdToken();
      const id = Cripto.DecryptedStorage('uid');
      const idAtendimento = Cripto.DecryptedStorage("triagem_atual");
      const atendimento = store.getState().atendimento;
      var pressaoArterial = '';
      var pressao = '';
      if (atendimento.vitalPressao !== 0) {
        pressaoArterial = atendimento.vitalPressao.toString();
        if (pressaoArterial.includes('/')) {
          pressaoArterial = atendimento.vitalPressao.replace('/', '');
          pressao = parseFloat(pressaoArterial)
        } else {
          pressao = parseFloat(pressaoArterial)
        }
      } else {
        pressao = parseFloat(atendimento.vitalPressao);
      }
      var temperatura = parseFloat(atendimento.vitalTemperatura);
      var peso = parseFloat(atendimento.vitalPeso);
      var altura = parseFloat(atendimento.vitalAltura);
      var frequenciaCardiaca = parseFloat(atendimento.vitalPulso);
      var frequenciaRespiratoria = parseFloat(atendimento.vitalFrequenciaRespiratoria);
      var saturacaoDeOxigenio = parseFloat(atendimento.vitalSpO2);
      request.patch(`/enfermeiro/${id}/atendimento/${idAtendimento}/updateSinaisVitais`, {
        idAtendimento: idAtendimento,
        idEnfermeiro: id,
        vitalTemperatura: temperatura,
        vitalPressao: pressao,
        vitalPulso: frequenciaCardiaca,
        vitalFrequenciaRespiratoria: frequenciaRespiratoria,
        vitalSpO2: saturacaoDeOxigenio,
        vitalPeso: peso,
        vitalAltura: altura,
        vitalTemperaturaNaoAferido: atendimento.vitalTemperaturaNaoAferido,
        vitalPressaoNaoAferido: atendimento.vitalPressaoNaoAferido,
        vitalPulsoNaoAferido: atendimento.vitalPulsoNaoAferido,
        vitalFrequenciaRespiratoriaNaoAferido: atendimento.vitalFrequenciaRespiratoriaNaoAferido,
        vitalSpO2NaoAferido: atendimento.vitalSpO2NaoAferido,
        vitalPesoNaoAferido: atendimento.vitalPesoNaoAferido,
        vitalAlturaNaoAferido: atendimento.vitalAlturaNaoAferido,
      }, {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      })
        .then((result) => {
          console.log(result)
          try {
            if (result.data.statusOk) {
              resolve();
            } else {
              reject();
            }
          } catch (error) {
            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'setSinaisVitais1', error, "")
            reject();
          }
        }).catch((error) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'setSinaisVitais2', error, "")
          reject();
        })
    } catch (error) {
      Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'setSinaisVitais3', error, "")
    }
  });
}

export const encerrarTriagem = async (ocorrencia) => {
  try {
    return await new Promise(async (resolve, reject) => {
      const id = Cripto.DecryptedStorage('uid');
      const atendimento = store.getState().atendimento;
      var idToken = await getIdToken();
      var text = replaceEspace(ocorrencia.descricao)
      if (atendimento.idAtendimento !== undefined && atendimento.idAtendimento !== '') {
        request.post(`/enfermeiro/${id}/atendimento/${atendimento.idAtendimento}/finalizaratendimento`, {
          "classificacaoRisco": atendimento.classificacaoRisco,
          "idPaciente": atendimento.idPaciente,
          "isOcorrencia": ocorrencia.isOcorrencia,
          "idOcorrencia": ocorrencia.idOcorrencia,
          "ocorrenciaTitulo": ocorrencia.ocorrenciaTitulo,
          "ocorrencia": text,
          "isClinicaDigital": atendimento.isClinicaDigital,
          "idTecEnfermagem": atendimento.idTecEnfermagem,
        }, {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }).then(async (result) => {
          if (result.data.statusOk) {
            resolve();
          } else {
            reject()
          }
        })
          .catch((error) => {
            console.log(error)
            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'encerrarTriagem1', error.message, "")
            reject()
          });
      } else {
        reject();
      }
    })
  } catch (ex) {
    console.log(ex)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'encerrarTriagem2', ex.message, "")
  }
};

export const getExames = async (idPaciente, isDependente, idDependente) => {
  try {
    if (isDependente == false) {
      var snapshot = await db
        .collection("pacientes")
        .doc(idPaciente)
        .collection("exames")
        .get();
      var exameList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let exameItem = { ...doc.data(), id: doc.id };
          exameList.push(exameItem);
        }
      });
      Cripto.EncryptedStorage(
        "triagem_paciente_exames",
        JSON.stringify(exameList)
      );

    } else {
      var snapshot = await db
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("exames")
        .get();
      var exameList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let exameItem = { ...doc.data(), id: doc.id };
          exameList.push(exameItem);
        }
      });
      Cripto.EncryptedStorage(
        "triagem_paciente_exames",
        JSON.stringify(exameList)
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getExames', ex.message, "")
  }
};

export const getAlergias = async (idPaciente, isDependente, idDependente) => {
  try {
    if (isDependente == false) {
      var snapshot = await db
        .collection("pacientes")
        .doc(idPaciente)
        .collection("alergias")
        .get();
      var alergiaList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let alergiaItem = { ...doc.data(), id: doc.id };
          alergiaList.push(alergiaItem);
        }
      });
      Cripto.EncryptedStorage(
        "triagem_paciente_alergias",
        JSON.stringify(alergiaList)
      );

    } else {
      var snapshot = await db
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("alergias")
        .get();
      var alergiaList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let alergiaItem = { ...doc.data(), id: doc.id };
          alergiaList.push(alergiaItem);
        }
      });
      Cripto.EncryptedStorage(
        "triagem_paciente_alergias",
        JSON.stringify(alergiaList)
      );

    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getAlergias', ex.message, "")
  }
};

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const getDocumentos = () => (dispatch) => {
  try {
    var idEnfermeiro = Cripto.DecryptedStorage('uid');
    var idTriagem = Cripto.DecryptedStorage("id_triagem");
    let documentos = [];

    db
      .collection("enfermeiros")
      .doc(idEnfermeiro)
      .collection("triagens")
      .doc(idTriagem)
      .collection("documentos")
      .get()
      .then((ee) => {
        ee.docs.map((doc) => {
          let documento = { ...doc.data(), id: doc.id };
          documentos.push(documento);
        });
        Cripto.EncryptedStorage("triagem_documentos", JSON.stringify(documentos));
        dispatch(triagensDocumentos(documentos));
      })
    //return Cripto.DecryptedStorage('triagem_documentos');
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getDocumentos', ex.message, "")
  }
};


export const getDocumentosSync = async () => {
  try {
    var idEnfermeiro = Cripto.DecryptedStorage('uid');
    var idTriagem = Cripto.DecryptedStorage("id_triagem");
    let documentos = [];
    return async dispatch => {
      var snap = await db
        .collection("enfermeiros")
        .doc(idEnfermeiro)
        .collection("triagens")
        .doc(idTriagem)
        .collection("documentos")
        .get();


      snap.docs.map((doc) => {
        let documento = { ...doc.data(), id: doc.id };
        documentos.push(documento);

      });

      Cripto.EncryptedStorage("triagem_documentos", JSON.stringify(documentos));
      dispatch(triagensDocumentos(documentos));
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getTriagensSync', ex.message, "")
  }
};


export const triagemCapturaPontos = async () => {
  try {
    var snapshot = await db
      .collection("pontosTriagens")
      .get();
    var pontos = [];
    snapshot.docs.map((doc) => {
      let item = { ...doc.data(), id: doc.id };
      pontos.push(item);
    });
    Cripto.EncryptedStorage("pontos_triagem_maps", JSON.stringify(pontos));
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemCapturaPontos', ex.message, "")
  }
};

export const triagemResetMonitoringCollectionAtendimento = () => {
  try {
    var uid = Cripto.DecryptedStorage('uid');
    return async (dispatch) => {
      var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
      perfil = perfil.toLowerCase();
      if (perfil != 'enfermeiros') {
        return;
      }
      if (uid == undefined || uid == null || uid == '')
        return;
      var totalTriagensCollection = 0;
      var totalTriagensRealizados = 0;
      var idTriagem = 0;
      try {
        const snapshot = await db.collection("enfermeiros").doc(uid).get();
        totalTriagensCollection = 1;
        totalTriagensRealizados = 0;
        var statusEnfermeiro = snapshot.data().statusEnfermeiro;
        idTriagem = snapshot.data().idAtendimento;
        await dispatch(triagemTotal(totalTriagensCollection, statusEnfermeiro, totalTriagensRealizados, idTriagem));
      } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemResetMonitoringCollectionAtendimento', ex.message, "")
        return -1;
      }
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemResetMonitoringCollectionAtendimento2', ex.message, "")
    return -1;
  }
}

export const triagemObserverCollectionTriagem = (that) => {
  try {
    var uid = Cripto.DecryptedStorage('uid');
    const docRef = db
      .collection("enfermeiros")
      .doc(uid);
    docData(docRef).subscribe(async (doc) => {
      if (doc.idAtendimento != '') {
        that.hasTriagemOberverById(
          doc.idAtendimento
        );
      }
    }
    );
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'TriagemObserverCollectionTriagem', ex.message, "")
  }
};

export const triagemIniciar = async () => {
  try {
    var uid = Cripto.DecryptedStorage('uid');
    var enfermeiroNome = Cripto.DecryptedStorage("enfermeiroNome");
    var enfermeiroCoren = Cripto.DecryptedStorage("enfermeiroCoren");
    var enfermeiroFoto = Cripto.DecryptedStorage("enfermeiroFoto");
    var idToken = await getIdToken();
    return await new Promise((resolve, reject) => {
      try {
        request.post(`/filatriagem/${uid}/atender`, {
          nomeEnfermeiro: enfermeiroNome,
          corenEnfermeiro: enfermeiroCoren,
          fotoEnfermeiro: enfermeiroFoto,
          colorEnfermeiro: Cripto.DecryptedStorage('color'),
        }, {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }).then(resp => {
          console.log(resp);
          if (resp.data.statusOk) {
            resolve()
          } else {
            reject()
          }
        })
      } catch (e) {
        console.log(e)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemIniciar3', e.message, "")
        reject("Erro ao chamar inicio de triagem - funcao");
      }
    })
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemIniciar4', ex.message, "")
  }
}


export const observableTriagem = () => (dispatch) => {
  try {
    const idEnfermeiro = Cripto.DecryptedStorage('uid');
    const idAtendimento = Cripto.DecryptedStorage('triagem_atual');
    const collectionEnfermeiro = db
      .collection("enfermeiros")
      .doc(idEnfermeiro)
      .collection('atendimentos')
      .doc(idAtendimento);
    docData(collectionEnfermeiro, 'uid').subscribe(async (atendimento) => {
      if (atendimento.historicoConsulta === undefined || atendimento.historicoConsulta.length === 0) {
        atendimento.historicoConsulta = [];
      }
      dispatch(atendimentoSetCurrent(atendimento))
    });
  } catch (error) {
    console.log(error)
  }
}

export const atendimentoIniciaCallTriagem = async () => {
  try {
    return await new Promise(async (resolve, reject) => {
      const idEnfermeiro = Cripto.DecryptedStorage('uid');
      const idAtendimento = Cripto.DecryptedStorage('triagem_atual');
      const pacienteId = store.getState().atendimento.idPaciente;
      var idToken = await getIdToken();
      request.post(`/enfermeiro/${idEnfermeiro}/atendimento/${idAtendimento}/paciente/${pacienteId}/chamarvideo`, {}, {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      })
        .then(response => {
          if (response.data.statusOk) {
            resolve();
          } else {
            reject()
          }
        })
        .catch(error => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciaCallTriagem1', error.message, "")
          reject();
        });
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciaCallTriagem2', ex.message, "")
  }
};

export const observableEnfermeiro = (that) => (dispatch) => {
  try {
    const idEnfermeiro = Cripto.DecryptedStorage('uid')
    const collectionMedico = db.collection("enfermeiros").doc(idEnfermeiro);
    docData(collectionMedico, "uid").subscribe((enfermeiro) => {
      Cripto.EncryptedStorage('triagem_atual', enfermeiro.idAtendimento)
      dispatch(statusEnfermeiro(enfermeiro.statusEnfermeiro));
      dispatch(triagensSetCurrentId(enfermeiro.idAtendimento));
      that.verificarEnfermeiroEmTriagem(enfermeiro.idAtendimento);
    });
  }
  catch (ex) {
    alert(ex.message)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'observableMedico', ex.message, "")
  }
}
export const triagemObservableFila = () => (dispatch) => {
  try {
    const collectionFila = db.collection("filaTriagem");
    collectionData(collectionFila, "uid").subscribe((msgs) => {
      var totalItens = 0;
      msgs.map((doc) => {
        totalItens++;
      });
      dispatch(triagemTotalFila(totalItens));
    });
  }
  catch (ex) {
    alert(ex.message)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemObservableFila', ex.message, "")
  }
};

export const verifyStatusEnfermeiro = () => {
  try {
    return async (dispatch) => {
      const idEnfermeiro = Cripto.DecryptedStorage('uid');
      var status = '';
      const docRef = db
        .collection("enfermeiros")
        .doc(idEnfermeiro);
      docData(docRef).subscribe((doc) => {
        status = doc.statusEnfermeiro
        dispatch(statusEnfermeiro(status));
      }
      )
    }
    /*
      0 : NORMAL
      1 : ATENDENDO
      2 : EM CONSULTA
      3 : CHAMANDO
      4 : EM VÍDEO CONFERENCIA
      9: PROCURANDO
    */
  } catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'verifyStatusPaciente', ex.message, "")
  }
}
export const triagemObservableTemposConsulta = () => {
  try {
    return async (dispatch) => {
      try {
        var idEnfermeiro = Cripto.DecryptedStorage('uid');
        var idTriagem = Cripto.DecryptedStorage("triagem_atual");

        var snapshot = await db
          .collection("enfermeiros")
          .doc(idEnfermeiro)
          .collection("atendimentos")
          .doc(idTriagem)
          .get();
        var tempo = {
          tempoEsperaFila: 0,
          tempoTriagem: 0,
        };

        if (snapshot.data() == undefined) return;

        var dataIniFila = snapshot.data().dataIniFila;
        var dataIniTriagem = snapshot.data().dataIniTriagem;
        var dataFimTriagem = snapshot.data().dataFimTriagem; //nothing to now

        var tempoEsperaFila = dataIniTriagem - dataIniFila;

        tempo.tempoEsperaFila =
          (tempoEsperaFila / 1000).toString().replace(/\D/g, "") * 1;
        tempo.tempoTriagem = new Date().getTime() - dataIniTriagem;

        await dispatch(triagemTempos(tempo));
        return true;
      } catch (e) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagenObservableTemposConsulta1', e.message, "")
        return false;
      }
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagenObservableTemposConsulta2', ex.message, "")
    return false;
  }
};

export const triagemObservableIndicadores = () => (dispatch) => {
  try {
    const docRef = db
      .collection("indicadores")
      .doc("tempoMedio");

    docData(docRef).subscribe((doc) => {
      var tempoMedioTriagem = 0;
      var tempoMedioEspera = 0;
      var tempoMedioTotalServico = 0;

      if (doc.tempoTriagem != undefined) {
        tempoMedioTriagem = doc.tempoTriagem;
      }
      if (doc.tempoEspera != undefined) {
        tempoMedioEspera = doc.tempoEspera;
      }
      if (doc.tempoNoSistema != undefined) {
        tempoMedioTotalServico = doc.tempoNoSistema;
      }

      var tempo = {
        tempoMedioTriagem: tempoMedioTriagem,
        tempoMedioEspera: tempoMedioEspera,
        tempoMedioTotalServico: tempoMedioTotalServico,
      };
      dispatch(triagemTemposMedios(tempo));
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemObservableIndicadores', ex.message, "")
  }
};

export const triagemFotoPerfilPaciente = async (idPaciente) => {
  try {
    Cripto.EncryptedStorage("triagem_paciente_foto_selfie", "");
    var snapshot = await db
      .collection("pacientes")
      .doc(idPaciente)
      .collection("profile")
      .doc(idPaciente)
      .get();
    if (snapshot.data().selfie != undefined && snapshot.data().selfie != null) {
      Cripto.EncryptedStorage(
        "triagem_paciente_foto_selfie",
        snapshot.data().selfie
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'triagemFotoPerfilPaciente', ex.message, "")
  }
};

export const setClassificacaoRisco = (classificacaoRisco) => {
  const idTriagem = Cripto.DecryptedStorage("triagem_atual");
  const idEnfermeiro = Cripto.DecryptedStorage('uid');
  try {
    db
      .collection('enfermeiros')
      .doc(idEnfermeiro)
      .collection('atendimentos')
      .doc(idTriagem)
      .update({
        classificacaoRisco: classificacaoRisco,
      })
  } catch (error) {
    console.log(error)
  }
}