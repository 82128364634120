import {
    CONTATOS_SET_FIELD,
    CONTATOS_SET_CURRENT,
} from "../actions";
import ContatoParceiroModel from "../Model/contatoParceiroModel";

const INITIAL_STATE = new ContatoParceiroModel().voidConstructor();

export default function dependenteReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONTATOS_SET_CURRENT:
            return action.contato;
        case CONTATOS_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}