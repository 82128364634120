import {
    SOLICITACAO_EXAMES_SET_FIELD,
    SOLICITACAO_SET_CURRENT
} from '../actions';

const INITIAL_STATE_SOLICITACAO = {
    indicacao: '',
    solicitacao: '',
}

export default function solicitacaoReducer(state = INITIAL_STATE_SOLICITACAO, action) {
    switch (action.type) {
         case SOLICITACAO_SET_CURRENT:
            return action.solicitacao;
        case SOLICITACAO_EXAMES_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}
