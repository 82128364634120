import {
	TRIAGENS_LISTAR,
	TRIAGENS_TOTAL,
	TRIAGENS_TOTAL_COLLECTION,
	TRIAGENS_TOTAL_ATENDIDOS,
	TRIAGEM_SET_CURRENT_ID,
	TRIAGEM_TOTAL_FILA,
	TRIAGEM_TEMPOS,
	TRIAGEM_TEMPOS_MEDIOS,
	TRIAGEM_DOCUMENTOS,
	STATUS_ENFERMEIRO,
	TRIAGEM_COUNT,
} from '../actions';

const INITIAL_STATE = {
	triagens: [],
	totalTriagensCollection: 0, //TOTAL DE ITENS NA COLLECTION
	totalTriagensRealizados: 0, //TOTAL DE Triagen REALIZADOS AOS PACIENTES
	triagem_current_id: 0,
	triagem_totalFila: 0,
	tempoTriagem: 0,
	tempoEsperaFila: 0,
	tempoMedioTriagem: 0,
	tempoMedioEspera: 0,
	tempoMedioTotalServico: 0,
	statusEnfermeiro: '',
	triagem_count: 0,
	total_documentos_anexos: 0
}

export default function dashboardReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case TRIAGENS_LISTAR:
			const newState1 = { ...state, triagens: action.triagens };
			return newState1;
		case TRIAGENS_TOTAL:
			const newState2 = {
				...state,
				totalTriagensCollection: action.totalTriagensCollection,
				totalTriagensRealizados: action.totalTriagensRealizados,
				triagem_current_id: action.triagem_current_id,
			};
			return newState2;
		case TRIAGENS_TOTAL_COLLECTION:
			const newState3 = { ...state, totalTriagensCollection: action.totalTriagensCollection };
			return newState3;

		case TRIAGENS_TOTAL_ATENDIDOS:
			const newState4 = { ...state, totalTriagensRealizados: action.totalTriagensRealizados };
			return newState4;

		case TRIAGEM_SET_CURRENT_ID:
			const newState5 = { ...state, triagem_current_id: action.triagem_current_id };
			return newState5;

		case TRIAGEM_TOTAL_FILA:
			const newState6 = { ...state, triagem_totalFila: action.triagem_totalFila };
			return newState6;
		case TRIAGEM_COUNT:
			const newStateCount = {
				...state, triagem_count: action.triagem_count,
			}
			return newStateCount;
		case TRIAGEM_TEMPOS:
			const newState7 = { ...state, tempoEsperaFila: action.tempoEsperaFila, tempoTriagem: action.tempoTriagem };
			return newState7;
		case TRIAGEM_TEMPOS_MEDIOS:
			const newState8 = {
				...state, tempoMedioTriagem: action.tempoMedioTriagem,
				tempoMedioEspera: action.tempoMedioEspera,
				tempoMedioTotalServico: action.tempoMedioTotalServico,
			};
			return newState8;
		case TRIAGEM_DOCUMENTOS:
			const newState9 = { ...state, documentos: action.documentos }
			return newState9;
		case STATUS_ENFERMEIRO:
			const newState10 =
			{
				...state,
				statusEnfermeiro: action.statusEnfermeiro
			};
			return newState10;
		default:
			return state;
	}
}
