import { AGENDA_LISTAR	} from '../actions';

const INITIAL_STATE = {
    agendas : [] 
}

export default function agendaListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AGENDA_LISTAR:
            return action.agendas;
		default:
			return state;
	}
}
