import { ENFERMEIRO_LISTAR	} from '../actions';

const INITIAL_STATE =  [    ] 


export default function enfermeirolistaReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ENFERMEIRO_LISTAR:
            return action.enfermeiros;
		default:
			return state;
	}
}