import axios from 'axios';
import firebase from 'firebase';
import { from } from 'rxjs';
import Cripto from '../utils/cripto.js';

export * from './administradorActions.js';
export * from './associadosActions.js';
export * from './ParceirosActions.js';
export * from './loginActions.js';
export * from './medicoActions.js';
export * from './operadoresActions.js';
export * from './permissoes.js';
export * from './dependenteActions.js';
export * from '../utils/utils.js';
export * from './contatoActions.js';
export * from './notificacoesActions.js';
export * from './chatActions.js';
export * from './atendimentoActions.js';
export * from './perfilActions.js';
export * from './dashboardFila.js';
export * from './antecedentesActions.js';
export * from './enfermeirosActions.js';
export * from './triagemActions.js';
export * from './tecnicoEnfermagemActions.js';
export * from './PerscricaoActions.js'
export * from './HipoteseActions.js'
export * from './ocorrenciaActions.js'
export * from './clinicaDigitalActions.js';
export * from './ClinicaDigitalCRUDActions.js';
export * from './hipoteseDiagnosticaActions.js';
export * from './turnoActions.js';
export * from './DocumentoRepository.js';
export * from './AgentePenitenciarioActions.js'
export * from './especialidadeAction.js'
export * from './horarioAction.js';
export * from './AgendaMedicoActions.js'
export * from './AgendarAtendimentoActions'
export * from './TotemActions'
export * from './indicadoresActions'

export const getIdToken = async () => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        firebase
          .auth()
          .onAuthStateChanged(async user => {
            const refreshedToken = await user
              .getIdToken(true)
              .catch(err => console.error(err))
            resolve(refreshedToken)
          }, reject)
      } catch (error) {
        console.log(error)
      }
    });
  } catch (error) {
    console.log(error)
  }
}
export const request = axios.create({
  baseURL: `${process.env.REACT_APP_URL_FUNCTIONS_ADDRESS}api`,
  headers: {
    'Accept': '*/*',
    'ContentType': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  //baseURL: 'http://localhost:3000',
})