import { HIPOTESE_LISTAR	} from '../actions';

const INITIAL_STATE = [] 

export default function hipoteseListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case HIPOTESE_LISTAR:
            return action.hipoteses;
		default:
			return state;
	}
}

