import { getColor } from "../utils/foto";

class AdministradorModel {
    constructor(ativo, celular, cpf, dataNasc, email, fotoPerfil, nome, password, telefone, id, sexo) {
        this.ativo = ativo;
        this.celular = celular;
        this.cpf = cpf;
        this.dataNasc = dataNasc;
        this.email = email;
        this.fotoPerfil = fotoPerfil;
        this.nome = nome;
        this.password = password;
        this.telefone = telefone;
        this.id = id;
        this.sexo = sexo;
    }
    voidConstructor() {
        var administradorModel = {
            ativo: true,
            celular: '',
            cpf: '',
            dataNasc: '',
            email: '',
            fotoPerfil: '',
            nome: '',
            password: '',
            telefone: '',
            id: '',
            sexo: '',
            color: getColor(),
        }
        return administradorModel;
    }

    createConstructor(administrador) {
        var administradorModel = {
            ativo: administrador.ativo,
            celular: administrador.celular,
            cpf: administrador.cpf,
            dataNasc: administrador.dataNasc,
            email: administrador.email,
            fotoPerfil: administrador.fotoPerfil,
            nome: administrador.nome,
            password: administrador.password,
            telefone: administrador.telefone,
            sexo: administrador.sexo,
            color: administrador.color
        }
        return administradorModel;
    }

    completeConstructor(administrador) {
        let administradorModel = {
            ativo: administrador.ativo,
            celular: administrador.celular,
            cpf: administrador.cpf,
            dataNasc: administrador.dataNasc,
            email: administrador.email,
            fotoPerfil: administrador.fotoPerfil,
            nome: administrador.nome,
            id: administrador.id,
            telefone: administrador.telefone,
            sexo: administrador.sexo,
            color: administrador.color
        }
        return administradorModel;
    }
}
export default AdministradorModel;