import React from 'react';
const ClinicaDigital = React.lazy(() => import('./View/ClinicaDigital/ClinicaDigital.jsx'));

const Login = React.lazy(() => import('./View/Login/Login'));
const Logoff = React.lazy(() => import('./View/Logoff'));
const RedefinirSenha = React.lazy(()=> import('./View/Redefinirsenha'));

const route = [
   
//-------------NOSSOS ITENS SEM MENU !!! -------------- //
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/logoff', exact: true, name: 'Logoff', component: Logoff },
    { path: '/redefinirsenha', exact: true, name: "Redefinir Senha", component: RedefinirSenha},
    { path: "/clinica/:idParceiro_idClinica", exact: true, name: "Atendimento Clínica Digital", component: ClinicaDigital}

];

export default route;