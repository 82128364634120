import { ADMINISTRADOR_LISTAR	} from '../actions';

const INITIAL_STATE = [];

export default function administradorListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ADMINISTRADOR_LISTAR:
            return action.administradores;
		default:
			return state;
	}
}