class ClinicaDigital {
    constructor(id, ativo, nome, tipo, parceiro, parceiroId) {
        this.id = id;
        this.ativo = ativo;
        this.nome = nome;
        this.tipo = tipo
        this.parceiro = parceiro;
        this.parceiroId = parceiroId;
    }
    voidConstructor(){
        var ClinicaModel = {
            id: '',
            ativo: true,
            nome: '',
            tipo: 'clinica',
            idAtendimento: "",
            parceiro: '',
            parceiroId: '',
            horarios: [],
            token: "",
            statusClinicaDigital: 0
        };
        return ClinicaModel;
    }
    createConstructor(clinicaDigital){
        var ClinicaModel = {
            id: clinicaDigital.id,
            ativo: clinicaDigital.ativo,
            nome: clinicaDigital.nome,
            tipo: clinicaDigital.tipo,
            idAtendimento: clinicaDigital.idAtendimento,
            parceiro: clinicaDigital.parceiro,
            parceiroId: clinicaDigital.parceiroId,
            horarios: clinicaDigital.horarios,
            token: clinicaDigital.token,
            statusClinicaDigital: clinicaDigital.statusClinicaDigital
        };
        return ClinicaModel;
    }
    completeConstructor(clinicaDigital){
        var ClinicaModel = {
            ativo: clinicaDigital.ativo,
            nome: clinicaDigital.nome,
            tipo: clinicaDigital.tipo,
            parceiro: clinicaDigital.parceiro,
            parceiroId: clinicaDigital.parceiroId,
            idAtendimento: clinicaDigital.idAtendimento,
            id: clinicaDigital.id,
            horarios: clinicaDigital.horarios,
            token: clinicaDigital.token,
            statusClinicaDigital: clinicaDigital.statusClinicaDigital
        };
        return ClinicaModel;
    }
}
export default ClinicaDigital;