import { SET_JA_PESQUISOU, SET_FIELD_FILTRO, FILTRO_SET_CURRENT, REMOVE_FILTRO, ADD_FILTRO, UPDATE_FILTRO, SET_LISTA_DE_MEDICOS, SET_LISTA_DE_ENFERMEIROS, SET_LISTA_DE_PARCEIROS, SET_MEDICO_SELECIONADO, SET_ENFERMEIRO_SELECIONADO, SET_PARCEIRO_SELECIONADO, SET_LISTA_DE_TECNICOS_DE_ENFERMAGEM, SET_TECNICO_DE_ENFERMAGEM_SELECIONADO, SET_LISTA_INDICADORES } from '../../../actions/indicadoresActions';

const INITIAL_STATE = {
    filtros: [],
    medicos: [],
    enfermeiros: [],
    parceiros: [],
    tecnicosDeEnfermagem: [],
    medicoSelecionado: "",
    tecnicoDeEnfermagemSelecionado: "",
    enfermeiroSelecionado: "",
    parceiroSelecionado: "",
    indicadores: [],
    filtro: {
        key: "",
        value: "",
        filter: "",
    },
    jaPesquisou: false,
}

export default function indicadoresReducer(state = INITIAL_STATE, action) {
    const newState = { ...state };
    switch (action.type) {
        case SET_LISTA_DE_MEDICOS:
            newState.medicos = action.value;
            return newState;
        case SET_LISTA_DE_ENFERMEIROS:
            newState.enfermeiros = action.value;
            return newState;
        case SET_LISTA_DE_PARCEIROS:
            newState.parceiros = action.value;
            return newState;
        case SET_LISTA_DE_TECNICOS_DE_ENFERMAGEM:
            newState.tecnicosDeEnfermagem = action.value;
            return newState;
        case SET_MEDICO_SELECIONADO:
            newState.medicoSelecionado = action.value;
            return newState;
        case SET_TECNICO_DE_ENFERMAGEM_SELECIONADO:
            newState.tecnicoDeEnfermagemSelecionado = action.value;
            return newState;
        case SET_ENFERMEIRO_SELECIONADO:
            newState.enfermeiroSelecionado = action.value;
            return newState;
        case SET_PARCEIRO_SELECIONADO:
            newState.parceiroSelecionado = action.value;
            return newState;
        case ADD_FILTRO:
            newState.filtros.push(action.value);
            newState.filtro = {};
            return newState;
        case REMOVE_FILTRO:
            newState.filtros = newState.filtros.filter(filtro => { return filtro !== action.value });
            return newState;
        case UPDATE_FILTRO:
            var index = newState.filtros.indexOf(action.value)
            newState.filtros[index] = action.value;
            newState.filtro = {};
            return newState;
        case FILTRO_SET_CURRENT:
            newState.filtro = action.filtro;
            return newState;
        case SET_FIELD_FILTRO:
            newState.filtro[action.field] = action.value;
            return newState;
        case SET_LISTA_INDICADORES:
            newState.indicadores = action.list;
            return newState;
        case SET_JA_PESQUISOU:
            newState.jaPesquisou = action.value;
            return newState;
        default:
            return state;
    }
}