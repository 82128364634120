import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import ContatoParceiroModel from '../Model/contatoParceiroModel';
import { getIdToken, replaceEspaceFieldsInObject, request } from '.';

export const CONTATOS_SET_FIELD = 'CONTATOS_SET_FIELD';
export const contatoSetField = (field, value) => {
    return {
        type: CONTATOS_SET_FIELD,
        field,
        value,
    }
}

export const CONTATOS_SET_CURRENT = 'CONTATOS_SET_CURRENT';
const contatoSetCurrent = contato => ({
    type: CONTATOS_SET_CURRENT,
    contato
});


export const contatoSetAtual = (idParceiro, idContato) => async dispatch => {
    if (idContato === null) {
        const contato = new ContatoParceiroModel().voidConstructor();
        dispatch(contatoSetCurrent(contato));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/contato/${idContato}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                const contato = new ContatoParceiroModel().completeConstructor(resp.data.data);
                dispatch(contatoSetCurrent(contato));
            }).catch(error => {
                console.log(error)
            });
    }
}

export const contatoAtualizar = async (idParceiro, idContato) => {
    var contato = new ContatoParceiroModel().completeConstructor(store.getState().contato);
    contato = replaceEspaceFieldsInObject(contato)
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/parceiro/${idParceiro}/contato/${idContato}`,
                contato,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error)
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}


export const contatoCriar = async (idParceiro,) => {
    var contato = new ContatoParceiroModel().createConstructor(store.getState().contato);
    contato = replaceEspaceFieldsInObject(contato)
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                `/parceiro/${idParceiro}/contato`,
                contato,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}


export const CONTATOS_LISTAR = 'CONTATOS_LISTAR';
export const contatoListar = (contato) => ({
    type: CONTATOS_LISTAR,
    contatos: contato,
});

export const contatosLista = (idParceiro) => {
    return async dispatch => {
        var contatoArr = [];
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/contatos`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                console.log(resp)
                if (resp.data.data.length > 0) {
                    resp.data.data.map(contato => {
                        let contatoModel = new ContatoParceiroModel().completeConstructor(contato);
                        contatoArr.push(contatoModel);
                    })
                    dispatch(contatoListar(contatoArr));
                } else {
                    dispatch(contatoListar([]));
                }
            }).catch(error => {
                return;
            });
    }
}