import Cripto from "../utils/cripto"

export class AgendaFactory {

    static initialAgenda() {
        return {
            data: 0,
            idMedico: "",
            isDisponivel: false,
            idPaciente: "",
            nomePaciente: "",
            fotoPaciente: "",
            colorPaciente: "",
            motivoConsulta: "",
            idParceiro: Cripto.DecryptedStorage('parceiroId'),
            nomeParceiro: Cripto.DecryptedStorage('parceiroNome'),
            dataIniFilaTriagem: 0,
            colorMedico: "",
            crmMedico: "",
            fotoMedico: "",
            nomeMedico: "",
            idAgentePenitenciario: Cripto.DecryptedStorage('uid'),
            nomeAgentePenitenciario: Cripto.DecryptedStorage('nome'),
            fotoAgentePenitenciario: Cripto.DecryptedStorage('fotoPerfil'),
            colorAgentePenitenciario: Cripto.DecryptedStorage('color'),
            id: "",
            isClinicaDigital: true,
            idClinicaDigital: "",
        }
    }

    static updateAgenda(agenda) {
        return {
            data: agenda.data === undefined ? "" : agenda.data,
            idMedico: agenda.idMedico === undefined ? "" : agenda.idMedico,
            isDisponivel: agenda.isDisponivel === undefined ? "" : agenda.isDisponivel,
            idPaciente: agenda.idPaciente === undefined ? "" : agenda.idPaciente,
            nomePaciente: agenda.nomePaciente === undefined ? "" : agenda.nomePaciente,
            fotoPaciente: agenda.fotoPaciente === undefined ? "" : agenda.fotoPaciente,
            colorPaciente: agenda.colorPaciente === undefined ? "" : agenda.colorPaciente,
            motivoConsulta: agenda.motivoConsulta === undefined ? "" : agenda.motivoConsulta,
            idParceiro: agenda.idParceiro === undefined ? "" : agenda.idParceiro,
            nomeParceiro: agenda.nomeParceiro === undefined ? "" : agenda.nomeParceiro,
            dataIniFilaTriagem: agenda.dataIniFilaTriagem === undefined ? "" : agenda.dataIniFilaTriagem,
            colorMedico: agenda.colorMedico === undefined ? "" : agenda.colorMedico,
            crmMedico: agenda.crmMedico === undefined ? "" : agenda.crmMedico,
            fotoMedico: agenda.fotoMedico === undefined ? "" : agenda.fotoMedico,
            nomeMedico: agenda.nomeMedico === undefined ? "" : agenda.nomeMedico,
            id: agenda.id === undefined ? "" : agenda.id,
            idAgentePenitenciario: agenda.idAgentePenitenciario === undefined ? "" : agenda.idAgentePenitenciario,
            nomeAgentePenitenciario: agenda.nomeAgentePenitenciario === undefined ? "" : agenda.nomeAgentePenitenciario,
            fotoAgentePenitenciario: agenda.fotoAgentePenitenciario === undefined ? "" : agenda.fotoAgentePenitenciario,
            colorAgentePenitenciario: agenda.colorAgentePenitenciario === undefined ? "" : agenda.colorAgentePenitenciario,
            isClinicaDigital: true,
            idClinicaDigital: "",
        }
    }
}
