import { USER_LOGIN_SUCCESS, USER_LOGOUT, USER_SET_DADOS, USER_SET_FIELD } from '../actions';

const INITIAL_STATE = {
	usuarioEmail: '',
	usuarioLogado: 0,
	statusVideoCall: 0,

	dadosUsuario: {
		id: '',
		nome: '',
		cpf: '',
		email: '',
		telefone: '',
		fotoPerfil: '',
		token : ''
	},
	
}


const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case USER_LOGIN_SUCCESS:
			return action.user;
		case USER_LOGOUT:
			return null;
		case USER_SET_FIELD:
			const newState = { ...state };
			newState.dadosUsuario[action.field] = action.value;
			return newState;
		case USER_SET_DADOS:
			const newState1 = { ...state, dadosUsuario: action.value };
			return newState1;
		case 'TOKEN':
			const newState2 = { ...state };
			newState2.dadosUsuario['token'] = action.value;
			return newState2;
			
		default:
			return state;
	}
}

export default userReducer;