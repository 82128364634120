import {
    RECEITUARIO_SIMPLES_SET_CURRENT,
    RECEITUARIO_SIMPLES_SET_FIELD
} from '../actions';

const INITIAL_STATE_RECEITUARIO_SIMPLES = {
    descricao: '',
    value: ''
}

export default function receituarioSimplesReducer(state = INITIAL_STATE_RECEITUARIO_SIMPLES, action) {
    switch (action.type) {
         case RECEITUARIO_SIMPLES_SET_CURRENT:
            return action.receituario;
        case RECEITUARIO_SIMPLES_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}
