import { CLINICA_DIGITAL_LOGIN, CLINICA_DIGITAL_GET_DADOS } from '../actions';
import SolicitacaoAtendimentoClinicaDigital from '../Model/SolicitacaoAtendimentoClinicaDigital';

const INITIAL_STATE = {
	loggedIn: false,
	clinica: {

    },
}

export default function clinicaDigital(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CLINICA_DIGITAL_LOGIN:
			const newState = { ...state };
			newState.loggedIn = action.value;
			return newState;
		case CLINICA_DIGITAL_GET_DADOS:
            const newState2 = {...state};
            newState2.clinica = action.value;
            return newState2;
		default:
			return state;
	}
}