import { store } from '../index.js'
import MedicoModel from '../Model/medicoModel';
import { getIdToken, request, replaceEspaceFieldsInObject } from './index.js';

export const MEDICO_SET_FIELD = 'MEDICO_SET_FIELD';
export const medicoSetField = (field, value) => {
    return {
        type: MEDICO_SET_FIELD,
        field,
        value,
    }
}
export const MEDICO_ADD_HORARIO = 'MEDICO_ADD_HORARIO';
export const medicoAddHorario = (value) => {
    return {
        type: MEDICO_ADD_HORARIO,
        value
    }
};

export const MEDICO_UPDATE_HORARIO = 'MEDICO_UPDATE_HORARIO';
export const medicoUpdateHorario = (index, value) => {
    return {
        type: MEDICO_UPDATE_HORARIO,
        index,
        value
    }
}

export const MEDICO_REMOVE_HORARIO = 'MEDICO_REMOVE_HORARIO';
export const medicoRemoveHorario = (value) => {
    return {
        type: MEDICO_REMOVE_HORARIO,
        value,
    }
}
export const MEDICO_SET_CURRENT = 'MEDICO_SET_CURRENT';
const medicoSetCurrent = medico => ({
    type: MEDICO_SET_CURRENT,
    medico
});

export const medicoSetAtual = (id) => async dispatch => {
    if (id == null) {
        var medico = new MedicoModel().voidConstructor();
        dispatch(medicoSetCurrent(medico));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/medico/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const medico = new MedicoModel().completeConstructor(resp.data.data);
                dispatch(medicoSetCurrent(medico));
            }).catch(error => {
                console.log(error)
            });
    }
};
export const getMedico = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            var medico = new MedicoModel().voidConstructor();
            resolve(medico);
        } else {
            var currentValue = store.getState().medicos;
            let medico = '';
            currentValue.map((item) => {
                if (item.id === id) {
                    medico = item;
                }
            });
            resolve(medico);
        }
    });
};

export const medicoAtualizar = async (id) => {
    var medico = new MedicoModel().completeConstructor(store.getState().medico);
    medico = replaceEspaceFieldsInObject(medico);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.patch(
            `/medico/${id}`,
            medico,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
};

export const medicoCriar = async () => {
    var medico =  replaceEspaceFieldsInObject(new MedicoModel().createConstructor(store.getState().medico));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(
            `/medico`,
            medico,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            console.log(resp)
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                if (resp.data.data === 'The email address is already in use by another account.') {
                    reject(resp.data.data);
                }
                reject(resp.data.message);
            }
        }).catch((error) => {
            console.log(error)
            reject(error)
        });
    });
};

export const medicoSetAtivo = async (idMedico, ativo) => {
    var medico = await getMedico(idMedico);
    return await new Promise(async (resolve, reject) => {
        medico.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/medico/${idMedico}`,
                medico,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//########################LISTAGEM!################################################################################
//#################################################################################################################
export const MEDICO_LISTAR = 'MEDICO_LISTAR';
const medicosListar = (medicos) => ({
    type: MEDICO_LISTAR,
    medicos: medicos,
});

export const medicosByEspecialidade = (especialidade) => {
    return async dispatch => {
        const arrMedicos = [];
        var idToken = await getIdToken();
        request.get(`/medicos/${especialidade}`, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(resp => {
                console.log(resp.data.data)
                resp.data.data.map(medico => {
                    arrMedicos.push({value: medico.id, label: medico.nome, crm: medico.crm, fotoPerfil: medico.fotoPerfil, color: medico.color})
                })
                return dispatch(medicosListar(arrMedicos));
            })
            .catch((error) => {
                return error;
            });
    };
}

export const medicosLista = (callback) => {
    return async dispatch => {
        const arrMedicos = [];
        var idToken = await getIdToken();
        request.get("/medicos", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(resp => {
                resp.data.data.map(medico => {
                    let medicoModel = new MedicoModel().completeConstructor(medico);
                    arrMedicos.push(medicoModel);
                })
                callback(arrMedicos);
                return dispatch(medicosListar(arrMedicos));
            })
            .catch((error) => {
                return error;
            });
    };
}
