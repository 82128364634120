class ContatoParceiroModel {
    constructor(nome, telefone, ramal, email, celular, id) {
        this.nome = nome;
        this.telefone = telefone;
        this.ramal = ramal;
        this.email = email;
        this.celular = celular;
        this.id = id;
    };

    voidConstructor() {
        var contatoModel = {
            nome: '',
            telefone: '',
            ramal: '',
            email: '',
            celular: '',
            id: '',
        }
        return contatoModel;
    };

    createConstructor(contato) {
        var contatoModel = {
            nome: contato.nome,
            telefone: contato.telefone,
            ramal: contato.ramal,
            email: contato.email,
            celular: contato.celular,
        }
        return contatoModel;
    };

    completeConstructor(contato) {
        var contatoModel = {
            nome: contato.nome,
            telefone: contato.telefone,
            ramal: contato.ramal,
            email: contato.email,
            celular: contato.celular,
            id: contato.id
        }
        return contatoModel;
    };

}
export default ContatoParceiroModel;