import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js'
import AdministradorModel from '../Model/administradorModel';
import { getIdToken, request } from '.';
import { replaceEspace } from '../utils/utils';

export const ADMINISTRADOR_SET_FIELD = 'ADMINISTRADOR_SET_FIELD';
export const administradorSetField = (field, value) => {
    return {
        type: ADMINISTRADOR_SET_FIELD,
        field,
        value,
    }
}

export const ADMINISTRADOR_SET_CURRENT = 'ADMINISTRADOR_SET_CURRENT';
const administradorSetCurrent = administrador => ({
    type: ADMINISTRADOR_SET_CURRENT,
    administrador
});

export const administradorSetAtual = (id) => async dispatch => {
    if (id === null) {
        var administrador = new AdministradorModel().voidConstructor();
        dispatch(administradorSetCurrent(administrador));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/administrador/${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                const administrador = new AdministradorModel().completeConstructor(resp.data.data);
                dispatch(administradorSetCurrent(administrador));
            }).catch(error => {
                console.log(error)
            });
    }
}

export const administradorAtualizar = async (id) => {
    var administrador = new AdministradorModel().completeConstructor(store.getState().administrador);
    administrador.nome = replaceEspace(administrador.nome)
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/administrador/${administrador.id}`,
                administrador,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}


export const administradorCriar = async (uid) => {
    var administrador = new AdministradorModel().createConstructor(store.getState().administrador);
    administrador.nome = replaceEspace(administrador.nome)
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                `/administrador`,
                administrador,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    if (resp.data.data === 'The email address is already in use by another account.') {
                        reject(resp.data.data);
                    }
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

export const getAdministrador = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            const administrador = new AdministradorModel().voidConstructor();
            resolve(administrador);
        } else {
            var currentValue = store.getState().administradores;
            currentValue.map((item) => {
                if (item.id === id) {
                    const administrador = new AdministradorModel().completeConstructor(item);
                    resolve(administrador);
                }
            });
        }
    });
}
export const administradorSetAtivo = async (idAdministrador, ativo) => {
    var administrador = await getAdministrador(idAdministrador);
    return await new Promise(async (resolve, reject) => {
        administrador.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/administrador/${idAdministrador}`,
                administrador,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//##################################################################################
//#########################LISTAGENS################################################
//##################################################################################
export const ADMINISTRADOR_LISTAR = 'ADMINISTRADOR_LISTAR';
const administradoresListar = (administradores) => ({
    type: ADMINISTRADOR_LISTAR,
    administradores: administradores,
});

export const administradorLista = (callBack) => {
    return async dispatch => {
        var arrAdms = [];
        var idToken = await getIdToken();
        request.get('/administradores',
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then((resp) => {
                console.log(resp)
                resp.data.data.map(adm => {
                    let administrador = new AdministradorModel().completeConstructor(adm);
                    arrAdms.push(administrador);
                });
                callBack(arrAdms)
                dispatch(administradoresListar(arrAdms));
            }).catch((error) => {
                console.log(error)
                return error;
            });
    }
}
