import {
    ADMINISTRADOR_SET_FIELD,
    ADMINISTRADOR_SET_CURRENT
} from '../actions';
import AdministradorModel from '../Model/administradorModel';

const INITIAL_STATE_PARCEIRO = new AdministradorModel().voidConstructor();

export default function administradorReducer(state = INITIAL_STATE_PARCEIRO, action) {
    switch (action.type) {
        case ADMINISTRADOR_SET_CURRENT:
            return action.administrador;
        case ADMINISTRADOR_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

