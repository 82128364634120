import {
    ATENDIMENTO_SET_FIELD,
    ATENDIMENTO_SET_CURRENT
} from '../actions';

const INITIAL_STATE_ATENDIMENTO =
    {

    };

export default function administradorReducer(state = INITIAL_STATE_ATENDIMENTO, action) {
    switch (action.type) {
        case ATENDIMENTO_SET_CURRENT:
            return action.atendimento;
        case ATENDIMENTO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

