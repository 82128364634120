import * as firebase from 'firebase/app';


var firebaseConfig = {
  apiKey: "AIzaSyApEpu_vcYH8WIwTQteUGgDK-IPvqW2P-U",
  authDomain: "srcolamedtest.firebaseapp.com",
  projectId: "srcolamedtest",
  storageBucket: "srcolamedtest.appspot.com",
  messagingSenderId: "756172643565",
  appId: "1:756172643565:web:d1fec23adaa98af5c740e4",
  measurementId: "G-BX347NZ4C0"
};
firebase.initializeApp(firebaseConfig);


  // Initialize Firebase
export default firebase;