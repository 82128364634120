import {
    LOGIN_SET_FIELD,
} from '../actions';

const INITIAL_STATE_ESTACAO = {
    email: '',
    senha: '',
}

export default function administradorReducer(state = INITIAL_STATE_ESTACAO, action) {
    switch (action.type) {
        case LOGIN_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

