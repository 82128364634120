import { HORARIO_LISTAR	} from '../actions';

const INITIAL_STATE = {
    horarios : [] 
}

export default function horarioListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case HORARIO_LISTAR:
            return action.horarios;
		default:
			return state;
	}
}
