import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import Cripto from '../utils/cripto'



export const NOTIFICACOES_SET_LIST = 'NOTIFICACOES_SET_LIST';
export const notificacoesSetList = (field, value) => {
    return {
        type: NOTIFICACOES_SET_LIST,
        field,
        value,
    }
}

export const NOTIFICACOES_OUTRAS_SET_LIST = 'NOTIFICACOES_OUTRAS_SET_LIST';
export const notificacoesOutrasSetList = (field, value) => {
    return {
        type: NOTIFICACOES_OUTRAS_SET_LIST,
        field,
        value,
    }
}

export const NOTIFICACOES_CLEAR = 'NOTIFICACOES_CLEAR';
export const notificaoesClear = (value) => {
    return {
        type: NOTIFICACOES_CLEAR,
        value,
    }
}



export const notificacoesCapturarList = () => {
    try{
        var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        perfil = perfil.toLowerCase();
    
        if (perfil === 'operadores' || perfil === 'medicos' || perfil === 'administradores' ) {
            var uid = Cripto.DecryptedStorage('uid');
            
            return async dispatch => {
                try {
                    var snapshot = await firebase.firestore().collection(perfil).doc(uid).collection('notificacoes').get()
                    var listNotifications = [];
                    snapshot.docs.map(doc => {
                        
                        if(doc.data().ativo !== undefined){
                            if(doc.data().ativo == true){
                                let tt = { id: doc.id, nome: doc.data().nome,  hora: doc.data().hora, msg: doc.data().msg,  img: doc.data().img   };
                                listNotifications.push(tt);
                            }
                        }

                    });
                    
                    dispatch(notificacoesSetList("notificacoesList", listNotifications));
                }
                catch (e) {
    
                }
            }
        } 
    }catch(e){

    }
}


export const notificacoesOutrasCapturarList = () => {
    try{
        var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        perfil = perfil.toLowerCase();
    
        if (perfil === 'operadores' || perfil === 'medicos' || perfil === 'administradores' ) {
            var uid = Cripto.DecryptedStorage('uid');
        
            return async dispatch => {
                try {
                    var snapshot = await firebase.firestore().collection(perfil).doc(uid).collection('notificacoesOutras').get()
                    var listNotifications = [];
                    snapshot.docs.map(doc => {
                        if(doc.data().ativo !== undefined){
                            if(doc.data().ativo == true){
                                let tt = { id: doc.id, nome: doc.data().nome,  hora: doc.data().hora, msg: doc.data().msg  };
                                listNotifications.push(tt);
                            }
                    }
                    });
                    
                    dispatch(notificacoesOutrasSetList("notificacoesOutrasList", listNotifications));
                }
                catch (e) {
                    
                }
            }
        } 
    }
    catch(e){

    }
}


export const notificacoesDelete = async (id) => {
    try{
        var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        perfil = perfil.toLowerCase();
    
        if (perfil === 'operadores' || perfil === 'medicos' || perfil === 'administradores' ) {
            var uid = Cripto.DecryptedStorage('uid');
            try {
                var snapshot = await firebase.firestore().collection(perfil).doc(uid).collection('notificacoes').doc(id).update({
                    ativo: false
                })
            }
            catch (e) {

            }
        } 
    }catch(e){

    }
}


export const notificacoesOutrasDelete = async (id) => {
    try{
    var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        perfil = perfil.toLowerCase();
    
        if (perfil === 'operadores' || perfil === 'medicos' || perfil === 'administradores' ) {
            var uid = Cripto.DecryptedStorage('uid');
            try {
                var snapshot = await firebase.firestore().collection(perfil).doc(uid).collection('notificacoesOutras').doc(id).update({
                    ativo: false
                })

            }
            catch (e) {

            }
        } 
    }catch(e){

    }
}


export const notificacoesClearList =  () => async dispatch => {
    await dispatch(notificaoesClear());
}