class HipoteseModel {


    constructor(id, codigoCID, ativo, descricao, nome) {
        this.id = id;
        this.codigoCID = codigoCID;
        this.ativo = ativo;
        this.descricao = descricao;
        this.nome = nome;

    };

    completeConstructor(hipotese) {
        var constructor = {
            id: hipotese.id,
            codigoCID: hipotese.codigoCID,
            ativo: hipotese.ativo,
            descricao: hipotese.descricao,
            nome: hipotese.nome,
        };
        return constructor;
    }
    voidConstructor() {
        var constructor = {
            id: '',
            codigoCID: '',
            ativo: true,
            descricao: '',
            nome: '',
        };
        return constructor;
    }

    createConstructor(hipotese) {
        var constructor = {
            codigoCID: hipotese.codigoCID,
            ativo: hipotese.ativo,
            descricao: hipotese.descricao,
            nome: hipotese.nome,
        };
        return constructor;
    }

}
export default HipoteseModel;