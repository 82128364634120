import {
    PERFIL_SET_FIELD,
    PERFIL_SET_CURRENT, LISTAR_PERFIS_SELECT
} from '../actions';

const INITIAL_STATE_CONVENIO = {
    nome: '',
    menu: [

    ],
    submenu: [

    ],
    perfilList: []
}

export default function perfilReducer(state = INITIAL_STATE_CONVENIO, action) {
    switch (action.type) {
        case PERFIL_SET_CURRENT:
            return action.perfil;
        case PERFIL_SET_FIELD:
            const newState = { ...state };
            newState.perfil[action.field] = action.perfil.value;
            return newState;
        case LISTAR_PERFIS_SELECT:
            const newState4 = { ...state, perfilList : action.value};
            return newState4;
        default:
            return state;
    }
}

