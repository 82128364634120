import { ESPECIALIDADE_LISTAR } from "../actions/especialidadeAction";

const INITIAL_STATE = {
    especialidades : [] 
}

export default function especialidadeListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ESPECIALIDADE_LISTAR:
            return action.especialidades;
		default:
			return state;
	}
}
