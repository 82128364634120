import { ESTACAO_LISTAR, ESTACAO_LISTAR_DISPONIVEIS } from '../actions';

const INITIAL_STATE = {
	estacoes: [],
	estacoesDisponiveis: [],
}

export default function estacoesListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ESTACAO_LISTAR:
			return action.estacoes;
		case ESTACAO_LISTAR_DISPONIVEIS:
			const newState = { ...state };
			newState[action.field] = action.value;
			return newState;
		default:
			return state;
	}
}