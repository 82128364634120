import Translate from "./Translate";

export const validaCNPJ = (cnpj) => {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;

}




export const getDateDDMMYYYYHHMMFromTimeStamp = (timeStamp) => {

    var date = new Date(timeStamp * 1);
    // Hours part from the timestamp

    var dia = "0";
    if (date.getDate() < 10) {
        dia = "0" + date.getDate();
    } else {
        dia = date.getDate();
    }

    var mes = "0";
    if (date.getMonth() + 1 < 10) {
        mes = "0" + (date.getMonth() + 1);
    } else {
        mes = (date.getMonth() + 1);
    }



    var hours = "0";
    if (date.getHours() < 10) {
        hours = "0" + date.getHours();
    } else {
        hours = date.getHours();
    }
    var minutes = "0";
    if (date.getMinutes() < 10) {

        minutes = "0" + date.getMinutes();
    } else {
        minutes = date.getMinutes();
    }
    // Seconds part from the timestamp
    var seconds = "0"
    if (date.getSeconds() < 10) {
        seconds = "0" + date.getSeconds();
    } else {
        seconds = date.getSeconds();
    }
    var strTime = hours + ":" + minutes;
    var formattedTime = dia + "/" + mes + "/" + date.getFullYear() + " " + strTime;
    return formattedTime;
}


export const getStrTempoEsperaFromTimeStamp = (timeStamp) => {

    var daysDifference = Math.floor(timeStamp / 1000 / 60 / 60 / 24);
    timeStamp -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(timeStamp / 1000 / 60 / 60);
    timeStamp -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(timeStamp / 1000 / 60);
    timeStamp -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(timeStamp / 1000);
    if (daysDifference > 0) {
        return daysDifference + " dias " + hoursDifference + " horas " + minutesDifference + " min";
    } else if (hoursDifference > 0) {
        return hoursDifference + " horas " + minutesDifference + " min"
    } else if (minutesDifference > 0) {
        return minutesDifference + " min " + secondsDifference + " seg";
    } else if (secondsDifference > 0) {
        return secondsDifference + " segundos";
    }
    else if (secondsDifference > 0) {
        return "0 segundo!";
    }

    return (hoursDifference * 60) + minutesDifference;

}


export const getSegundosFromTimeStamp = (timeStamp) => {

    var seconds = new Date(timeStamp).getTime() / 1000;
    return seconds;
}


export const getDateDDMMYYYYFromTimeStamp = (timeStamp) => {
    try {
        if (timeStamp === null) {
            return '';
        }
        var date = new Date(timeStamp * 1);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();
        var formattedTime = date.getDate() + "/" + getMonth(date) + "/" + date.getFullYear()

        return formattedTime;
    } catch (error) {
        console.log(error)
    }
}

function getMonth(date) {
    var mes = date.getMonth() + 1;
    var mesFormatted = mes >= 10 ? mes : '0' + mes;
    return mesFormatted;
}

export const getTimeStampFromDate = (date) => {
    var myDate = date.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    var d = new Date(newDate).getTime();

    return d;
}

export const CalculaIdadeTimeStamp = (dataNasc) => {
    var dataNascimento = new Date(dataNasc);
    var dataNascimento = dataNascimento.getDate() + "/" + (dataNascimento.getMonth() + 1) + "/" + dataNascimento.getFullYear();
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNascimento.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    var diffMeses;
    var diffDias;
    var TotalDiasDoMes = new Date(dataAtual.getFullYear(), (dataAtual.getMonth() + 1), 0).getDate();
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;
    if (anoAtual > anoNasc) {
        if (mesAtual < mesNasc) {
            idade--;
        } else {
            //Se estiver no mes do nascimento, verificar o dia
            if (mesAtual == mesNasc) {
                if (new Date().getDate() < diaNasc) {

                    //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                    idade--;
                }
            }
        }
        if (idade === 0) {
            if (mesAtual < mesNasc) {
                diffMeses = mesNasc - mesAtual;
                idade = diffMeses / 12;
            } else if (mesAtual > mesNasc) {
                diffMeses = mesAtual - mesNasc;
                idade = diffMeses / 12;
            } else {
                if (mesAtual == mesNasc) {
                    if (new Date().getDate() < diaNasc) {
                        diffDias = diaNasc - new Date().getDate();
                        idade = (diffDias) / TotalDiasDoMes;
                        idade = idade / 12;
                        //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                    }
                }
            }
        }
    } else {
        if (idade == 0) {
            if (anoAtual == anoNasc) {
                if (mesAtual < mesNasc) {
                    diffMeses = mesNasc - mesAtual;
                    idade = diffMeses / 12;
                } else if (mesAtual > mesNasc) {
                    diffMeses = mesAtual - mesNasc;
                    idade = diffMeses / 12;
                } else {
                    if (mesAtual == mesNasc) {
                        if (new Date().getDate() < diaNasc) {
                            diffDias = diaNasc - new Date().getDate();
                            idade = (diffDias) / TotalDiasDoMes;
                            idade = idade / 12;
                            //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                        }
                    }
                }
            }
        }
    }
    return idade;
}

export const CalculaIdade = (dataNasc) => {
    var dataNascToString = getDateDDMMYYYYFromTimeStamp(dataNasc);
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNascToString.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
        idade--;
    } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual == mesNasc) {
            if (new Date().getDate() < diaNasc) {
                //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                idade--;
            }
        }
    }
    return idade;
}

export const ValidaNome = (nome) => {
    return true;
    var nomeSplit = nome.split(" ");
    if (nome.match(/^(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'][^\s]*\s*?)(?!.*[ ]$))+$/)) {
        if (nomeSplit[1] != undefined && nomeSplit[1].length >= 2) {
            if (nomeSplit[1].length >= 4) {
                return true;
            } else if (nomeSplit[2] != undefined && nomeSplit[2].length >= 4) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const getDateFromTimeStamp = (timeStamp) => {

    var date = new Date((timeStamp));
    return date;
    // Hours part from the timestamp 
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    var formattedTime = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();

    return formattedTime;
}
export function validaEmail() {

}
export const validaCampoTextArea = (value) => {
    try {
        if (value !== undefined && value.length > 0) {
            if (value.match(/[ \S]*[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+[ \S]*/g)) {
                return true;
            }
            return false
        }
    } catch (error) {
        console.log(error)
        return false;
    }
}

export const validaCampo = (value) => {
    try {
        if (value !== undefined && value.length > 0) {
            if (value.match(/\s{6,}(\w+)?(\s{6,})?/g)) {
                return false;
            }
            return true;
        }
        return true;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const validaCampoAlfanumerico = (value, callback, minLength, maxLength) => {
    ///Aqui vamos ver quantos espaços o usuario deu na string inteira com map
    //Se o map der p/ exemplo 3 apenas ele retorna false, se for maior que 5 ele retorna true
    const ERRO_MIN_LENGTH = false;
    const SUCESSO_MIN_LENGTH = true;
    const ERRO_VALIDATOR = false;
    const SUCESSO_VALIDATOR = true;
    const OCULTA_VALIDATOR = true;
    const NAO_OCULTA_VALIDATOR = false;
    const ERRO_MAX_LENGTH = false;
    const SUCESSO_MAX_LENGTH = true;

    var arrayValue = validaTexto(value);
    if (arrayValue.length < minLength) {
        callback(ERRO_MIN_LENGTH, SUCESSO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false
    }
    if (arrayValue.length > maxLength) {
        callback(SUCESSO_MIN_LENGTH, SUCESSO_VALIDATOR, ERRO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false
    }
    if (value.match(/^(\s)+$/g)) {
        callback(SUCESSO_MIN_LENGTH, ERRO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false;
    }
    if (value.match(/[ \S]*[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+[ \S]*/g)) {
        callback(SUCESSO_MIN_LENGTH, SUCESSO_VALIDATOR, SUCESSO_MAX_LENGTH, OCULTA_VALIDATOR)
        return true
    }
    callback(SUCESSO_MIN_LENGTH, ERRO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
    return false
}



export const replaceEspace = (value) => {
    try {
        if (typeof value === 'string') {
            value = value.replace(/(^( )+)|( )+$|( {2,})/g, ' ');
            value = value.trim();
            return value;
        }
        return value;
    } catch (error) {
        return value
    }
}

export const validaSomenteLetras = (value) => {
    try {
        if (value != undefined && value.length > 0) {
            if (value.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/g)) {
                return true;
            }
            return false;
        }

    }
    catch (error) {
        console.log(error)
        return false;
    }
}

export const getHours = (time) => {
    try {
        var date = new Date(time);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();

        // Will display time in 10:30:23 format
        var formattedTime = hours + ':' + minutes.substr(-2);

        return formattedTime;

        // var hora = time.getHours();
        // var minuto = time.getMinutes();
        // return hora + ":" + minuto;
    } catch (error) {
        console.log(error)
        return ''
    }
}
export const validaTexto = (value) => {
    var arrayValue = value.split('');
    arrayValue = arrayValue.filter((char) => { return char != ' ' })
    return arrayValue;
}
export const validaCamposAlpha = (value, callback, minLength, maxLength) => {
    ///Aqui vamos ver quantos espaços o usuario deu na string inteira com map
    //Se o map der p/ exemplo 3 apenas ele retorna false, se for maior que 5 ele retorna true
    const ERRO_MIN_LENGTH = false;
    const SUCESSO_MIN_LENGTH = true;
    const ERRO_VALIDATOR = false;
    const SUCESSO_VALIDATOR = true;
    const OCULTA_VALIDATOR = true;
    const NAO_OCULTA_VALIDATOR = false;
    const ERRO_MAX_LENGTH = false;
    const SUCESSO_MAX_LENGTH = true;
    var arrayValue = validaTexto(value);
    if (arrayValue.length < minLength) {
        callback(ERRO_MIN_LENGTH, SUCESSO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false
    }
    if (value.match(/^(\s)+$/g)) {
        callback(SUCESSO_MIN_LENGTH, ERRO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false;
    }
    if (arrayValue.length > maxLength) {
        callback(SUCESSO_MIN_LENGTH, SUCESSO_VALIDATOR, ERRO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
        return false;
    }

    if (value.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/g)) {
        callback(SUCESSO_MIN_LENGTH, SUCESSO_VALIDATOR, SUCESSO_MAX_LENGTH, OCULTA_VALIDATOR)
        return true
    }
    callback(SUCESSO_MIN_LENGTH, ERRO_VALIDATOR, SUCESSO_MAX_LENGTH, NAO_OCULTA_VALIDATOR)
    return false
}
export const validaTextArea = (value, minLength) => {
    var arrayValue = validaTexto(value);
    if (arrayValue.length < minLength) {
        return false
    }
    return true
}
export const replaceEspaceFieldsInObject = (object) => {
    try {
        for (var field in object) {
            object[field] = replaceEspace(object[field]);
        }
        return object;
    } catch (err) {
        console.log(err)
        return object
    }
}


export const validaNivel = (e) => {
    if (e >= 0 && e <= 9) {
        return true
    }
    return false
}

export function timeStampToMinutes(date1) {
    var difference = date1;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);

    return daysDifference + hoursDifference + minutesDifference + secondsDifference;
}
export function timeStampToMinutesToString(date1) {
    var difference = date1;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60
    
    var secondsDifference = Math.floor(difference / 1000);
    var daysString = daysDifference === 0 || daysDifference === NaN ? "" : daysDifference + " " + Translate.translate('dias');
    var hoursString = hoursDifference === 0 || hoursDifference === NaN ? "" : hoursDifference + " " + Translate.translate('horas');
    var minutesString = minutesDifference === 0 || minutesDifference === NaN ? "" : minutesDifference + " " + Translate.translate('minutos');
    var secondsString = secondsDifference === 0 || secondsDifference === NaN ? "" : secondsDifference + " " + Translate.translate('segundos');
    
    var stringTempo = daysString !== '' ? daysString + ', ' : '';
    stringTempo += hoursString !== '' ?  daysString === '' ? '' + hoursString : " " + hoursString : '';
    stringTempo += minutesString !== '' ? hoursString === '' ? '' + minutesString : ", " + minutesString : '';
    stringTempo += secondsString !== '' ? minutesString === '' ? '' + secondsString : " e " + secondsString : '';
    console.log(stringTempo)
    return stringTempo;

}
export const minutesToTimeStamp = (minute) => {
    var ts = minute * 60 * 1000;
    return ts
}

