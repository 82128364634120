import Cripto from '../utils/cripto'
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { docData } from "rxfire/firestore";
import Log from '../utils/log'
import { storage } from "../config/firebasestorage";
import { store } from '../index.js'
import { getColor } from '../utils/foto';
import { getIdToken, request } from '.';

firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        Cripto.EncryptedStorage('logado', 'true');
        Cripto.EncryptedStorage('token', user.refreshToken); // usuario da maquina esta na storage!!! (obrigatorio!)
        await userPerfilToken(Cripto.DecryptedStorage('token'));
        //alert('setou token!: ['+ user.refreshToken + "]");

        return async (dispatch) => {
            dispatch(userSetDados("token", user.refreshToken));
        }
        //userPerfilToken();
    } else {
        if (window.location.toString().toLowerCase().includes("login")) {
            Cripto.EncryptedStorage('logado', 'false');
        } else {
            if (window.location.href.includes('clinica')) {
                return;
            }
            window.location.assign("/login")
        }
    }
});


export const LOGIN_SET_FIELD = 'LOGIN_SET_FIELD';
export const loginSetField = (field, value) => {
    return {
        type: LOGIN_SET_FIELD,
        field,
        value,
    }
}


export const verificarUsuarioLogadoFirebase = () => {
    if (Cripto.DecryptedStorage('logado') === 'true')
        return true;
    else
        return false;
}

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const userLoginSuccess = user => ({
    type: USER_LOGIN_SUCCESS,
    user
});

export const USER_LOGOUT = 'USER_LOGOUT';
const userLogout = () => ({
    type: USER_LOGOUT,
});

export const USER_SET_FIELD = 'USER_SET_FIELD';
export const userSetField = (field, value) => {
    return {
        type: USER_SET_FIELD,
        field,
        value,
    }
}


export const USER_SET_DADOS = 'USER_SET_DADOS';
export const userSetDados = (field, value) => ({
    type: USER_SET_DADOS,
    field,
    value,
});



export const userPerfilToken = async (token) => {


    try {
        var uid = Cripto.DecryptedStorage('uid');
        var snapshot = await firebase.firestore().collection('usuarios').doc(uid).update({
            token: token
        })
    } catch (ex) {
    }
}
export const userSetCookies = async (token) => {

    var uid = Cripto.DecryptedStorage('uid');
    Cripto.EncryptedStorage('cookies', true);
    try {
        var snapshot = await firebase.firestore().collection('usuarios').doc(uid).update({
            cookies: true
        })
    } catch (ex) {
    }
}


export const verificarLoginComParam = (newToken) => {
    if (newToken != null) {
        if (newToken != Cripto.DecryptedStorage('token') && newToken != '') {
            if (Cripto.DecryptedStorage('token') != newToken) {
                alert("Seu usuário acabou de executar login em outro navegador!")
                window.location.assign('/login');
                return
            }
        }
    }
}

export const userObservableToken = () => {
    var uid = Cripto.DecryptedStorage('uid');

    const docRef = firebase
        .firestore()
        .collection("usuarios")
        .doc(uid);
    docData(docRef).subscribe((doc) => {
        // verificarLoginComParam(doc.token)
        return async dispatch => {
            dispatch(userSetField("token", doc.token));
        }
    });
};


export const logOff = () => dispatch => {
    return (
        firebase
            .auth()
            .signOut()
            .then(() => {
                const action = userLogout();
                Cripto.EncryptedStorage('email', '');
                //   Cripto.EncryptedStorage('password', '');
                var itensLog = [];
                Cripto.EncryptedStorage('perfil', '');
                Cripto.EncryptedStorage('dependentes_cadastrar', '');
                Cripto.EncryptedStorage('id_parceiro', '');
                Cripto.EncryptedStorage('parceiroSelected', '');
                Cripto.EncryptedStorage('permissao_telas_usuario', '');
                Cripto.EncryptedStorage('collapsed_menu', 'false');
                Cripto.EncryptedStorage('cabine', '');
                Cripto.EncryptedStorage('parceiro', '');

                dispatch(action);
                return true;
            })
            .catch(error => {
                return Promise.reject(error)
            })
    )
}

export const tryRedefinirSenha = (email) => dispatch => {
    return (
        firebase.auth().sendPasswordResetEmail(email)
            .then((result) => {

                return true;
            })
            .catch((error) => {

                return Promise.reject(error)
            })
    )
}


export const tryLogin = (email, password) => dispatch => {
    return (
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(async (user) => {
                firebase.auth().currentUser.getIdToken().then(function (idToken) {
                    return Cripto.EncryptedStorage('idToken', idToken);
                    //console.log(idToken);
                }).catch(function (error) {
                    // Handle error
                });
                const action = userLoginSuccess(user);
                //  Cripto.EncryptedStorage('password', password);
                dispatch(action);
                const { currentUser } = firebase.auth();
                return user;
            })
            .catch(error => {
                return Promise.reject(error)
            }
            )
    )
}

export const userPerfil = async (email) => {
    const { currentUser } = firebase.auth();
    Cripto.EncryptedStorage('uid', currentUser.uid);
    Cripto.EncryptedStorage('token', currentUser.refreshToken); // usuario da maquina esta na storage!!! (obrigatorio!)
    Cripto.EncryptedStorage('email', email);

    var db = firebase.firestore();
    var query = await db.collection('usuarios').doc(currentUser.uid).get();
    // alert('doc.data().cookies')
    return await new Promise(async (resolve, reject) => {
        if (query.exists) {
            if (query.data().cookies !== undefined) {
                Cripto.EncryptedStorage('cookies', query.data().cookies)
                //alert(doc.data().cookies)
            } else {
                Cripto.EncryptedStorage('cookies', 'false')
            }
            Cripto.EncryptedStorage('perfil', query.data().tipo);
            if (query.data().layout !== undefined) {
                if (query.data().layout == 'dark') {
                    Cripto.EncryptedStorage('color_cliente', 'dark');
                } else {
                    Cripto.EncryptedStorage('color_cliente', 'original');
                }
            } else {
                Cripto.EncryptedStorage('color_cliente', 'original');
            }
            if (query.data().layoutSuperiorAtendimento !== undefined) {
                if (query.data().layoutSuperiorAtendimento == true) {
                    Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'true');
                } else {
                    Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'false');
                }
            } else {
                Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'false');
            }
            await getTelasUsuario(currentUser.uid);
            resolve(query.data().tipo);
        } else {
            reject('usuarioNaoEncontrado')
        }
        //alert('EEEEEE!');
    });
}
export const getTelasUsuario = async (id) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var db = firebase.firestore();
            var collectionPerfil = await db.collection('usuarios').doc(id).collection('perfilWeb').get();
            collectionPerfil.docs.map(perfil => {
                //    alert('SETOU!');
                Cripto.EncryptedStorage('permissao_telas_usuario', JSON.stringify(perfil.data()))
            });
            resolve()
        } catch (e) {
            // alert(e)
            reject()
        }
    })
}

export const userPerfilSetLayout = (color) => {
    var uid = Cripto.DecryptedStorage('uid');
    var db = firebase.firestore();
    db.collection('usuarios').doc(uid).update({
        layout: color
    })
        .then((result) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
}




export const userPerfilSetLayoutSuperiorAtendimento = (ativo) => {
    var uid = Cripto.DecryptedStorage('uid');
    var db = firebase.firestore();
    db.collection('usuarios').doc(uid).update({
        layoutSuperiorAtendimento: ativo
    })
        .then((result) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
}
export const setColor = async (perfilName, uid) => {
    try {
        var color = getColor()
        await firebase.firestore().collection(perfilName).doc(uid).update({
            color: color
        }).then(success => {
            console.log(color)
            Cripto.EncryptedStorage('color', color);
            return color;
        }).catch(error => {
            return error;
        })
    } catch (error) {
        console.log(error)
        return error;
    }
}
export const userPerfilGetData = async (perfil) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var uid = Cripto.DecryptedStorage('uid');
            var db = firebase.firestore();
            var perfilName = perfil;
            if (perfil !== 'tecEnfermagem' && perfil !== 'agentesPenitenciarios') {
                perfilName = perfil.toLowerCase();
            }
            const snapshot = await db.collection(perfilName).doc(uid).get();
            Cripto.EncryptedStorage('parceiroSelected', JSON.stringify(''));
            Cripto.EncryptedStorage('nome', snapshot.data().nome);
            if (snapshot.idiomaValueSigla === undefined) {
                var userLang = navigator.language;
                Cripto.EncryptedStorage("idioma", userLang);
                await db.collection(perfilName).doc(uid).update({ idiomaValueSigla: userLang });
            }
            Cripto.EncryptedStorage('fotoPerfil', snapshot.data().fotoPerfil);
            Cripto.EncryptedStorage("idioma", snapshot.data().idiomaValueSigla);
            if (snapshot.data().color === undefined) {
                await setColor(perfilName, uid);
            } else {
                Cripto.EncryptedStorage('color', snapshot.data().color);
            }
            if (perfil == "operadores") {
                var parceiros = snapshot.data().parceiros;
                Cripto.EncryptedStorage('parceiros', JSON.stringify(parceiros));
            } else if (perfil === 'tecEnfermagem') {
                Cripto.EncryptedStorage('nomeClinicaDigital', snapshot.data().clinicaDigitalNome);
                Cripto.EncryptedStorage('idClinicaDigital', snapshot.data().idClinicaDigital);
                Cripto.EncryptedStorage('parceiroNome', snapshot.data().parceiroNome);
                Cripto.EncryptedStorage('parceiroId', snapshot.data().idParceiro);
                const parceiro = await db.collection('parceiros').doc(snapshot.data().idParceiro).get();
                console.log("parceiro");

                console.log(parceiro.data().isPenitenciaria);
                var isPenitenciaria = parceiro.data().isPenitenciaria === undefined ? false : parceiro.data().isPenitenciaria;
                console.log(isPenitenciaria);
                Cripto.EncryptedStorage('isPenitenciaria', isPenitenciaria);
                await getTipoEstacao(snapshot.data().idParceiro, snapshot.data().idClinicaDigital).then((tipoEstacao) => {
                    Cripto.EncryptedStorage('tipoEstacao', tipoEstacao);
                }).catch(error => {
                    reject(error);
                });
            } else if (perfil === 'agentesPenitenciarios') {
                Cripto.EncryptedStorage('parceiroNome', snapshot.data().parceiro);
                Cripto.EncryptedStorage('parceiroId', snapshot.data().idParceiro);
            }
            const API = await db.collection("apis").doc("soha").get();
            Cripto.EncryptedStorage("urlAPI", API.data().url);
            Cripto.EncryptedStorage("tokenAPI", API.data().token);
            resolve(snapshot.data().ativo);
        }
        catch (e) {
            reject()
            console.log(e)
        }
    })
}

const getTipoEstacao = async (idParceiro, idClinicaDigital) => {
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/clinicadigital/${idClinicaDigital}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.data.tipo === undefined) {
                    resolve("clinica");
                } else {
                    resolve(resp.data.data.tipo);
                }
            }).catch((error) => {
                reject()
            })
    })
}

export const userGetDadosUsuario = () => {
    try {
        var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        if (perfil !== 'tecEnfermagem' && perfil !== 'agentesPenitenciarios') {
            perfil = perfil.toLowerCase();
        }
        var uid = Cripto.DecryptedStorage('uid');
        return async dispatch => {
            try {
                var doc = await firebase.firestore().collection(perfil).doc(uid).get()
                let dadosUsuario = {};
                dadosUsuario.id = doc.id;
                dadosUsuario.nome = doc.data().nome;
                dadosUsuario.cpf = doc.data().cpf;
                dadosUsuario.telefone = doc.data().telefone;
                dadosUsuario.email = doc.data().email;
                dadosUsuario.fotoPerfil = doc.data().fotoPerfil;
                dadosUsuario.idioma = doc.data().idiomaValueSigla;
                if (perfil === 'medicos') {
                    Cripto.EncryptedStorage('medicoNome', doc.data().nome);
                    Cripto.EncryptedStorage('medicoCrm', doc.data().crm);
                    Cripto.EncryptedStorage('medicoFoto', doc.data().fotoPerfil);
                    Cripto.EncryptedStorage('especialidade', doc.data().especialidades);
                    Cripto.EncryptedStorage('medicoUf', doc.data().uf);
                } else if (perfil === 'enfermeiros') {
                    Cripto.EncryptedStorage('enfermeiroNome', doc.data().nome);
                    Cripto.EncryptedStorage('enfermeiroCoren', doc.data().coren);
                    Cripto.EncryptedStorage('enfermeiroFoto', doc.data().fotoPerfil);
                    Cripto.EncryptedStorage('enfermeiroUf', doc.data().uf);
                } else {
                    Cripto.EncryptedStorage('tecEnfermeiroNome', doc.data().nome);
                    Cripto.EncryptedStorage('tecEnfermeiroCoren', doc.data().coren);
                    Cripto.EncryptedStorage('tecEnfermeiroFoto', doc.data().fotoPerfil);
                    Cripto.EncryptedStorage('tecEnfermeiroUf', doc.data().uf);
                }
                dispatch(userSetDados("dadosUsuario", dadosUsuario));
            }
            catch (e) {
                console.log(e)
            }
        }
    }
    catch (e) {
        console.log(e)
    }
}

export const salvarPerfil = async (foto) => {
    try {
        return await new Promise((resolve, reject) => {
            var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
            if (perfil !== 'tecEnfermagem') {
                perfil = perfil.toLowerCase();
            }
            var uid = Cripto.DecryptedStorage('uid');
            var db = firebase.firestore();
            var urlToFb = '';
            var today = new Date();
            // N0ME DO ARQUIVO COMPOSTO PELO ID DO MEDICO,, MAIS NOME ARQUIVO,, MAIS DIA E HORA
            var nameFile = uid + foto.name + today.getDate();
            const uploadTask = storage.ref(`images/${nameFile}`).put(foto);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                error => {
                },
                () => {
                    storage
                        .ref("images")
                        .child(nameFile)
                        .getDownloadURL()
                        .then(url => {
                            Cripto.EncryptedStorage('fotoPerfil', url);
                            console.log(url)
                            urlToFb = url;
                            db.collection(perfil).doc(uid).update({
                                fotoPerfil: urlToFb
                            })
                                .then((result) => {
                                    resolve();
                                })
                                .catch((error) => {
                                    reject();
                                })
                        });
                }
            )
        });
    }
    catch (e) {
        console.log(e)
    }
}
export const salvarIdioma = async () => {
    return await new Promise((resolve, reject) => {
        var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
        var uid = Cripto.DecryptedStorage('uid');
        if (perfil !== 'tecEnfermagem') {
            perfil = perfil.toLowerCase();
        }
        var dadosUsuario = store.getState().user.dadosUsuario;
        firebase.firestore()
            .collection(perfil)
            .doc(uid).update({
                idiomaValueSigla: dadosUsuario.idioma
            }).then((success) => {
                Cripto.EncryptedStorage("idioma", dadosUsuario.idioma);
                resolve();
            }).catch(error => {
                reject();
            })
    });
}

export const updateEmailAndPassword = () => {
    firebase.auth().signInWithEmailAndPassword('fernando.salvetti@srcsistemas.com.br', 'Src@123@').then((user) => {
        console.log(user.user.toJSON());
        user.user.updatePassword('blalbap')
        user.user.updateEmail('fernando.giuseppe@gmail.com').then((resp) => {
            console.log('trocou de email?')
        }).catch((error) => {
            console.log(error)
        });
    });
}