import { AgendaFactory } from '../Model/AgendaFactory';
import { AGENDA_ATENDIMENTO_SET_FIELD, AGENDA_ATENDIMENTO_SET_CURRENT } from '../actions'
const INITIAL_STATE = AgendaFactory.initialAgenda();

export default function agendaListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AGENDA_ATENDIMENTO_SET_CURRENT:
            return action.agenda;
        case AGENDA_ATENDIMENTO_SET_FIELD:
            console.log(action.field + ":" + action.value)
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}
