import { TEC_ENFERMAGEM_LISTAR	} from '../actions';

const INITIAL_STATE = {
    tecEnfermeiros : [    ] 
}

export default function tecEnfermagemlistaReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case TEC_ENFERMAGEM_LISTAR:
            return action.tecEnfermeiros;
		default:
			return state;
	}
}