import firebase from '../config/firebase';
import Cripto from '../utils/cripto'
import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../index.js'
import { dispatch } from 'rxjs/internal/observable/range';
import { adicionaMascaraCPF, removeMaskFromCPF } from '../utils/cpf';

export const PERFIL_LISTAR = 'PERFIL_LISTAR';
const perfilListar = (perfis) => ({
    type: PERFIL_LISTAR,
    perfis: perfis,
});

export const LISTAR_PERFIS_SELECT = 'LISTAR_PERFIS_SELECT';
const listarPerfisSelect = (field, value) => ({
    type: LISTAR_PERFIS_SELECT,
    field,
    value,
});


export const PERFIL_SET_FIELD = 'PERFIL_SET_FIELD';
export const perfilSetField = (field, value) => {
    return {
        type: PERFIL_SET_FIELD,
        field,
        value,
    }
}

export const PERFIL_SET_CURRENT = 'PERFIL_SET_CURRENT';
const perfilSetCurrent = perfil => ({
    type: PERFIL_SET_CURRENT,
    perfil
});

export const perfilSetAtual = (id) => dispatch => {

    if (id == null) {
        var perfil = [

        ]
        dispatch(perfilSetCurrent(perfil));
    } else {
        var currentValue = store.getState().perfis;
        let perfil = '';


        currentValue.map((item) => {
            if (item.id === id) {
                perfil = item;
            }
        });
        Cripto.EncryptedStorage('perfis_telas', JSON.stringify(perfil));

        dispatch(perfilSetCurrent(perfil));
    }

}




export const perfilCriar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).set({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });
        return true;
    } catch (e) {
        return false;
    }
}

export const perfilAtualizar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).update({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });

        return true;
    } catch (e) {
        return false;
    }
}


export const perfilGetListaToGrid = () => {
    return async dispatch => {
        var id = Cripto.DecryptedStorage('uid');
        var snapshot = await firebase.firestore().collection('perfilWeb').get();
        var item = [];
        snapshot.docs.map(doc => {
            let tt = {
                id: doc.id,
                menu: doc.data().menu.toString().substring(0, 20) + "[....]",
                submenu: doc.data().submenu.toString().substring(0, 20) + "[....]"
            }
            item.push(tt);
        });
        //        Cripto.EncryptedStorage('perfis_telas', JSON.stringify(item));
        dispatch(perfilListar(item));
    }
}

export const perfilGetPerfil = async (id) => {
    var snapshot = await firebase.firestore().collection('perfilWeb').doc(id).get();
    let tt = {}
    tt = {
        id: snapshot.id,
        menu: snapshot.data().menu,
        submenu: snapshot.data().submenu
    }
    return tt;
}
export const findUserByCPF = async (perfil, cpf) => {
    var cpfComMascara = adicionaMascaraCPF(cpf);
    var cpfSemMascara = removeMaskFromCPF(cpf)
    const user = await firebase.firestore().collection(perfil).where('cpf', '==', cpfSemMascara).get();
    var id; var nome;
    if (user.empty) {
        const userFind = await firebase.firestore().collection(perfil).where('cpf', '==', cpfComMascara).get();
        if (!userFind.empty) {
            userFind.forEach(user => { id = user.id; nome = user.data().nome; })
            return { id: id, nome: nome };
        }
        return { id: id, nome: nome };
    } else {
        user.forEach(user => { id = user.id; nome = user.data().nome; })
       return { id: id, nome: nome }
    }
}
export const perfilGetUser = async (perfil, cpf) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var user = await findUserByCPF(perfil, cpf);
            var perfilUsuario = await getPerfilUsuario(user.id);
            var usuario = { id: user.id, nome: user.nome, perfil: perfilUsuario.perfil, tipoPerfil: perfilUsuario.tipoPerfil, }
            resolve(usuario);
        } catch (error) {
            console.log(error);
            reject({});
        }
    });
}

export const getPerfilUsuario = async (idUsuario) => {
    var usuario = await firebase.firestore().collection("usuarios").doc(idUsuario).get();
    var perfilUsuario  = await usuario.ref.collection("perfilWeb").doc(usuario.data().tipo).get();
    return { tipoPerfil: usuario.data().tipo, perfil: { menu: perfilUsuario.data().menu, submenu: perfilUsuario.data().submenu} };
}

export const carregaListaPerfisDisponiveis = () => {
    try {
        return async dispatch => {
            try {
                var snapshot = await firebase.firestore().collection('perfilWeb').get();
                var listPerfis = [];
                snapshot.docs.map(doc => {
                    let tt = { value: doc.id, label: doc.id, color: '#00B8D9' };
                    listPerfis.push(tt);
                });
                dispatch(listarPerfisSelect("perfilList", listPerfis));
            } catch (e) {

            }
        }
    } catch (error) {

    }
}
export const atualizarPerfilPorUsuario = async (idUsuario, idPerfilWebAtual, idPerfilWeb, menu, submenu) => {
    try {
        return await new Promise((resolve, reject) => {
            if (idPerfilWebAtual != "") {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWebAtual).delete().then((success) => {
                        firebase.firestore()
                            .collection('usuarios')
                            .doc(idUsuario)
                            .collection('perfilWeb')
                            .doc(idPerfilWeb)
                            .set({
                                menu: menu,
                                submenu: submenu,
                                tipo: idPerfilWebAtual
                            }).then((success) => {
                                resolve('sucesso')
                            }).catch((error) => {
                                reject('error')
                            });
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWeb)
                    .set({
                        menu: menu,
                        submenu: submenu,
                    }).then((success) => {
                        resolve('sucesso')
                    }).catch((error) => {
                        reject('error')
                    });
            }
        });
    } catch (error) {
    }
}




export const atualizarPerfilTodosUsuario = async (idPerfilWebAtual, menu, submenu) => {
    try {
        let querySnapshot = await firebase.firestore().collectionGroup('perfilWeb').where('tipo', '==', idPerfilWebAtual).get();
        querySnapshot.forEach((doc) => {
            doc.ref.set({
                menu: menu,
                submenu: submenu,
                tipo: idPerfilWebAtual
            })
        });

    } catch (error) {
    }
}



export const deletePerfilPorUsuario = async (idUsuario, idPerfilWeb) => {
    try {
        return await new Promise((resolve, reject) => {

        });
    } catch (error) {

    }
}

