import {
    ATESTADO_SET_FIELD,
    ATESTADO_SET_CURRENT
} from '../actions';

const INITIAL_STATE_ATESTADO = {
    periodo: '',
    observacoes: '',
}

export default function atestadoReducer(state = INITIAL_STATE_ATESTADO, action) {
    switch (action.type) {
         case ATESTADO_SET_CURRENT:
            return action.atestado;
        case ATESTADO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}
