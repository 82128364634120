export const ValidaCPF = (strCPF) => {
    try {
        var Soma;
        var Resto;
        Soma = 0;
        var testeCpfReplace = strCPF.replace('.', '');
        testeCpfReplace = testeCpfReplace.replace('-', '');
        testeCpfReplace = testeCpfReplace.replace('.', '');
        if (testeCpfReplace == "00000000000"
            || testeCpfReplace == "11111111111"
            || testeCpfReplace == "22222222222"
            || testeCpfReplace == "33333333333"
            || testeCpfReplace == "44444444444"
            || testeCpfReplace == "55555555555"
            || testeCpfReplace == "66666666666"
            || testeCpfReplace == "77777777777"
            || testeCpfReplace == "88888888888"
            || testeCpfReplace == "99999999999") return false;
        for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(testeCpfReplace.substring(9, 10))) return false;

        Soma = 0;
        for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(testeCpfReplace.substring(10, 11))) return false
        return true;
    } catch (error) {
        console.log(strCPF)
        console.log(error)
        return false;
    }
}
export const ValidaCPFDep = (strCPF) => {
    try {
        if(strCPF.length == 0) return true;
        var Soma;
        var Resto;
        Soma = 0;
        var testeCpfReplace = strCPF.replace('.', '');
        testeCpfReplace = testeCpfReplace.replace('-', '');
        testeCpfReplace = testeCpfReplace.replace('.', '');
        if (testeCpfReplace == "00000000000"
            || testeCpfReplace == "11111111111"
            || testeCpfReplace == "22222222222"
            || testeCpfReplace == "33333333333"
            || testeCpfReplace == "44444444444"
            || testeCpfReplace == "55555555555"
            || testeCpfReplace == "66666666666"
            || testeCpfReplace == "77777777777"
            || testeCpfReplace == "88888888888"
            || testeCpfReplace == "99999999999") return false;
        for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(testeCpfReplace.substring(9, 10))) return false;

        Soma = 0;
        for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(testeCpfReplace.substring(10, 11))) return false
        return true;
    } catch (error) {
        console.log(error)
        return false;
    }
}
export const removeMaskFromCPF = (cpf) => {
    var cpfWithoutMask = cpf.replace('.', '');
    cpfWithoutMask = cpfWithoutMask.replace('-', '');
    cpfWithoutMask = cpfWithoutMask.replace('.', '');
    return cpfWithoutMask;
};

export const adicionaMascaraCPF = (cpf) => {
    //retira os caracteres indesejados...
    //retira os caracteres indesejados...
    try {
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    } catch (error) {
        console.log(error)
    }
};