import firebase from '../config/firebase';

import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../index.js'
import EnfermeiroModel from '../Model/enfermeiroModel.js';
import { getIdToken, replaceEspaceFieldsInObject, request } from '.';

export const ENFERMEIRO_SET_FIELD = 'ENFERMEIRO_SET_FIELD';
export const enfermeiroSetField = (field, value) => {
    return {
        type: ENFERMEIRO_SET_FIELD,
        field,
        value,
    }
}

export const ENFERMEIRO_SET_CURRENT = 'ENFERMEIRO_SET_CURRENT';
const enfermeiroSetCurrent = enfermeiro => ({
    type: ENFERMEIRO_SET_CURRENT,
    enfermeiro
});

export const enfermeiroSetAtual = (id) => async dispatch => {
    if (id == null) {
        var enfermeiro = new EnfermeiroModel().voidConstructor();
        dispatch(enfermeiroSetCurrent(enfermeiro));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/enfermeiro/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const enfermeiro = new EnfermeiroModel().completeConstructor(resp.data.data);
                dispatch(enfermeiroSetCurrent(enfermeiro));
            }).catch(error => {
                console.log(error)
            });
    };
};

export const enfermeiroAtualizar = async (id) => {
    var enfermeiro = store.getState().enfermeiro;
    enfermeiro = replaceEspaceFieldsInObject(enfermeiro);

    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.patch(
            `/enfermeiro/${id}`,
            enfermeiro,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve()
            } else {
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

export const enfermeiroCriar = async () => {
    var enfermeiro = new EnfermeiroModel().createConstructor(store.getState().enfermeiro);
    enfermeiro = replaceEspaceFieldsInObject(enfermeiro);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(
            `/enfermeiro`,
            enfermeiro,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                if (resp.data.data === 'The email address is already in use by another account.') {
                    reject(resp.data.data);
                }
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

export const getEnfermeiro = async (idEnfermeiro) => {
    return await new Promise((resolve, reject) => {
        if (idEnfermeiro == null) {
            var enfermeiro = new EnfermeiroModel().voidConstructor();
            resolve(enfermeiro);
        } else {
            var currentValue = store.getState().enfermeiros;
            let enfermeiro = '';
            currentValue.map((item) => {
                if (item.id === idEnfermeiro) {
                    enfermeiro = new EnfermeiroModel().completeConstructor(item);
                }
            });
            resolve(enfermeiro);
        }
    });
};

export const enfermeiroSetAtivo = async (idEnfermeiro, ativo) => {
    var enfermeiro = await getEnfermeiro(idEnfermeiro);
    return await new Promise(async (resolve, reject) => {
        enfermeiro.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/enfermeiro/${idEnfermeiro}`,
                enfermeiro,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
};


//########################LISTAGEM!################################################################################
//#################################################################################################################
export const ENFERMEIRO_LISTAR = 'ENFERMEIRO_LISTAR';
const enfermeirosListar = (enfermeiros) => ({
    type: ENFERMEIRO_LISTAR,
    enfermeiros: enfermeiros,
});


export const enfermeirosLista = (callback) => {
    return async dispatch => {
        const arrEnfermeiros = [];
        var idToken = await getIdToken();
        request.get("/enfermeiros",
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                resp.data.data.map(enfermeiro => {
                    var enfermeiroModel = new EnfermeiroModel().completeConstructor(enfermeiro);
                    arrEnfermeiros.push(enfermeiroModel);
                })
                callback(arrEnfermeiros);
                dispatch(enfermeirosListar(arrEnfermeiros));
            })
            .catch((error) => {
                return error;
            });
    };
};
