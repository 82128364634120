import firebase from '../config/firebase';
import Cripto from '../utils/cripto'
import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import ClienteModel from '../Model/clienteModel';
import { getIdToken, replaceEspace, replaceEspaceFieldsInObject, request } from '.';
import { removeMaskFromCPF } from '../utils/cpf';

export const CLIENTES_SET_FIELD = 'CLIENTES_SET_FIELD';
export const clienteSetField = (field, value) => {
    return {
        type: CLIENTES_SET_FIELD,
        field,
        value,
    }
}

export const CLIENTES_SET_CURRENT = 'CLIENTES_SET_CURRENT';
const clienteSetCurrent = cliente => ({
    type: CLIENTES_SET_CURRENT,
    cliente
});

export const CLIENTES_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS = 'CLIENTES_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS';
export const parceirosList = (field, value) => {
    return {
        type: CLIENTES_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS,
        field,
        value,
    }
}


export const CLIENTES_SET_CURRENT_LIST_PARCEIRO = 'CLIENTES_SET_CURRENT_LIST_PARCEIRO';
export const clienteSetCurrentListParceiro = (field, value) => {
    return {
        type: CLIENTES_SET_CURRENT_LIST_PARCEIRO,
        field,
        value,
    }
}

export const CLIENTES_SET_CURRENT_SEXO = 'CLIENTES_SET_CURRENT_SEXO';
export const clientesSetCurrentSexo = (field, value) => {
    return {
        type: CLIENTES_SET_CURRENT_SEXO,
        field,
        value,
    }
}



export const clienteSetAtual = (id, idParceiro) => async dispatch => {
    if (id === null) {
        const cliente = new ClienteModel().voidConstructor();
        dispatch(clienteSetCurrent(cliente));
    } else {
        var idToken = await getIdToken();
        request.get(`/parceiro/${idParceiro}/cliente/${id}`,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then((resp) => {
                const cliente = new ClienteModel().completeConstructor(resp.data.data);
                dispatch(clienteSetCurrent(cliente));
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const carregaListarParceirosDisponiveis = (callbackParceiros) => {
    try {
        return async dispatch => {
            var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
            if (perfil !== 'agentesPenitenciarios') {
                perfil = perfil.toLowerCase();
            }
            if (perfil === 'operadores') {
                var uid = Cripto.DecryptedStorage('uid');
                await firebase.firestore().collection('operadores').doc(uid).get()
                    .then((doc) => {
                        var parceiros = doc.data().parceiros;
                        dispatch(parceirosList("parceirosDisponiveis", parceiros));
                        if(callbackParceiros !== undefined){
                            callbackParceiros(parceiros)
                        }
                    });
            } else if (perfil === 'agentesPenitenciarios') {
                var uid = Cripto.DecryptedStorage('uid');
                await firebase.firestore().collection('agentesPenitenciarios').doc(uid).get()
                    .then((doc) => {
                        console.log(doc.data());
                        var parceiro = [{label: doc.data().parceiro, value: doc.data().idParceiro}];
                        dispatch(parceirosList("parceirosDisponiveis", parceiro));
                    });
            } else if (perfil === 'administradores') {
                try {
                    var snapshot = await firebase.firestore().collection('parceiros').get();
                    var parceiros = [];
                    snapshot.docs.map(doc => {
                        if (doc.data().ativo) {
                            let tt = { value: doc.id, label: doc.data().nome, color: '#00B8D9' };
                            parceiros.push(tt);
                        }
                    });
                    dispatch(parceirosList("parceirosDisponiveis", parceiros));
                    if(callbackParceiros !== undefined){
                        callbackParceiros(parceiros)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            }
        }
    } catch (e) {
        console.log(e)
    }
}

export const clienteAtualizar = async (idParceiro, id) => {
    var cliente = new ClienteModel().completeConstructor(store.getState().cliente);
    return await new Promise(async (resolve, reject) => {
        cliente = replaceEspaceFieldsInObject(cliente);
        var idToken = await getIdToken();
        request
            .patch(`/parceiro/${cliente.idParceiro}/cliente/${cliente.id}`,
                cliente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export const clienteCriar = async (idParceiro, uid) => {
    var cliente = new ClienteModel().createConstructor(store.getState().cliente);
    cliente.id = removeMaskFromCPF(cliente.cpf);
    cliente = replaceEspaceFieldsInObject(cliente);
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request
                .post(`/parceiro/${idParceiro}/cliente`, cliente,
                    {
                        headers: {
                            "Authorization": `Bearer ${idToken}`,
                        }
                    }
                )
                .then((resp) => {
                    if (resp.data.statusOk) {
                        resolve(true)
                    } else {
                        if (resp.data.data === 'The email address is already in use by another account.') {
                            reject(resp.data.data);
                        }
                        reject(resp.data.message);
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        } catch (error) {
            reject(error)
        }
    });
}

export const getCliente = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente vazia
            const clienteModel = new ClienteModel().voidConstructor();
            resolve(clienteModel);
        } else {
            var currentValue = store.getState().clientes.clientes;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    const clienteModel = new ClienteModel().completeConstructor(item);
                    resolve(clienteModel);
                }
            });
        }
    });
};

export const clienteSetAtivo = async (id, ativo) => {
    try {
        var idParceiro = id[0];
        var idCliente = id[1];
        var cliente = await getCliente(idCliente);
        cliente.ativo = ativo ? false : true;
        console.log("AAAAA")
        console.log(cliente.ativo)
        return await new Promise(async (resolve, reject) => {
            var idToken = await getIdToken();
            request
                .patch(`/parceiro/${idParceiro}/cliente/${idCliente}`, cliente,
                    {
                        headers: {
                            "Authorization": `Bearer ${idToken}`,
                        }
                    }
                ).then((resp) => {
                    console.log(resp)
                    if (resp.data.statusOk) {
                        resolve(true)
                    } else {
                        reject(resp.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });
        });
    } catch (error) {
        console.log(error)
    }

}

export const CLIENTES_LISTAR = 'CLIENTES_LISTAR';
const clienteListar = (cliente) => ({
    type: CLIENTES_LISTAR,
    cliente: cliente,
});

export const clientesLista = (idParceiro, callBack) => {
    return async dispatch => {
        var arrCliente = [];
        var idToken = await getIdToken();
        request(`/parceiro/${idParceiro}/clientes`, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then((resp) => {
                resp.data.data.map(cliente => {
                    var clienteModel = new ClienteModel().completeConstructor(cliente);
                    arrCliente.push(clienteModel);
                });
                callBack(arrCliente);
                dispatch(clienteListar(arrCliente));
            })
            .catch((error) => {
                console.log(error);
            })
    }
}