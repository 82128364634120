import {
    AGENTE_PENITENCIARIO_SET_FIELD,
    AGENTE_PENITENCIARIO_SET_CURRENT
} from '../actions';
import { AgentePenitenciarioModel } from '../Model/AgentePenitenciarioModel';

const INITIAL_STATE_AGENTE_PENITENCIARIO = new AgentePenitenciarioModel().voidConstructor();
console.log(INITIAL_STATE_AGENTE_PENITENCIARIO)
export default function agentePenitenciarioReducer(state = INITIAL_STATE_AGENTE_PENITENCIARIO, action) {
    switch (action.type) {
        case AGENTE_PENITENCIARIO_SET_CURRENT:
            return action.agentePenitenciario;
        case AGENTE_PENITENCIARIO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

