export default class Documento {

    constructor(tipo, texto, numeroDias, indicacaoClinica, medicoNome, medicoCRM, medicoUF, pacienteNome, pacienteCPF, medicoId, atendimentoId,) {
        this.tipo = tipo;
        this.texto = texto;
        this.numeroDias = numeroDias;
        this.indicacaoClinica = indicacaoClinica;
        this.medicoNome = medicoNome;
        this.medicoCRM = medicoCRM;
        this.medicoUF = medicoUF;
        this.pacienteNome = pacienteNome;
        this.pacienteCPF = pacienteCPF;
        this.medicoId = medicoId;
        this.atendimentoId = atendimentoId;
    };
}