import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import { getIdToken, request } from '.';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';
import HorarioAgendaMedica from '../Model/HorarioAgendaMedico.js';

//Muda um campo especifico do horario atual na redux
export const HORARIO_SET_FIELD = 'HORARIO_SET_FIELD';
export const horarioSetField = (field, value) => {
    return {
        type: HORARIO_SET_FIELD,
        field,
        value,
    }
}
//Muda o horario atual na redux
export const HORARIO_SET_CURRENT = 'HORARIO_SET_CURRENT';
export const horarioSetCurrent = horario => {
    return {
        type: HORARIO_SET_CURRENT,
        horario
    }
};

//Para não fazer uma nova requisição é chamada essa Function que pega o horario desejado na lista da Redux
export const getHorario = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Horario e chama o metodo que constrói a Classe de horario vazia
            const horario = new HorarioAgendaMedica().voidConstructor();
            resolve(horario);
        } else {
            var currentValue = store.getState().horariosList;
            console.log(currentValue)
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model horario e chama o metodo que constrói a Classe de horario
                    const horario = new HorarioAgendaMedica().completeConstructor(item);
                    resolve(horario);
                }
            });
        }
    });
}




//Envia o horario para tela de Create ou Update 
export const horarioSetAtual = (idMedico, idHorario) => async dispatch => {
    if (idHorario == null) {
        var horario = new HorarioAgendaMedica().voidConstructor();
        dispatch(horarioSetCurrent(horario));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/medico/${idMedico}/horario/${idHorario}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const horario = new HorarioAgendaMedica().completeConstructor(resp.data.data);
                dispatch(horarioSetCurrent(horario));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar o horario
export const horarioAtualizar = async (idMedico, idHorario) => {
    //Faz a chamada do model horario e chama o metodo que constrói a Classe de horario para atualizar
    var horarioModel = replaceEspaceFieldsInObject(new HorarioAgendaMedica().completeConstructor(store.getState().horario));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/medico/${idMedico}/horario/${idHorario}`,
                //Body
                horarioModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

export const excluirHorario = async (idMedico, idHorario) => {
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.delete(
                `/medico/${idMedico}/horario/${idHorario}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    })
}

//Faz a requisição para cadastrar o horario
export const horarioCriar = async (idMedico) => {
    //Faz a chamada do model horario e chama o metodo que constrói a Classe de horario para cadastrar um novo horario
    var horarioModel = replaceEspaceFieldsInObject(new HorarioAgendaMedica().completeConstructor(store.getState().horario));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                `/medico/${idMedico}/horario`,
                //Body
                horarioModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error)
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}

// app.get('/medico/:medicoId/horarios', horarioMedicoController.getAllHorarios);
// app.get('/medico/:medicoId/horario/:horarioId', horarioMedicoController.getByIdHorario);
// app.post('/medico/:medicoId/horario', horarioMedicoController.addHorario);
// app.patch('/medico/:medicoId/horario/:horarioId', horarioMedicoController.updateHorario);
// app.delete('/medico/:medicoId/horario/:horarioId', horarioMedicoController.deleteHorario);

//########################LISTAGEM!################################################################################
export const HORARIO_LISTAR = 'HORARIO_LISTAR';
const horarioListar = (horario) => ({
    type: HORARIO_LISTAR,
    horarios: horario,
});

//Chama a requisição para listar todos os horarios
export const horariosLista = (idMedico, callback) => {
    return async dispatch => {
        const arrHorarios = [];
        var idToken = await getIdToken();
        request
            .get(`medico/${idMedico}/horarios`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(horario => {
                    //Faz a chamada do model horario e chama o metodo que constrói a Classe de horario
                    let horarioModel = new HorarioAgendaMedica().completeConstructor(horario)
                    arrHorarios.push(horarioModel);
                })
                //Envia o array de horarios para a redux
                callback(arrHorarios);
                return dispatch(horarioListar(arrHorarios));
            }).catch(error => {
                console.log(error)
            });
    }
};

export default horarioAtualizar