import firebase from '../config/firebase';
import DependenteModel from '../Model/dependenteModel';
import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../index.js'
import { getIdToken, replaceEspaceFieldsInObject, request } from '.';

export const DEPENDENTES_SET_FIELD = 'DEPENDENTES_SET_FIELD';
export const dependenteSetField = (field, value) => {
    return {
        type: DEPENDENTES_SET_FIELD,
        field,
        value,
    }
}

export const DEPENDENTES_SET_CURRENT = 'DEPENDENTES_SET_CURRENT';
const dependenteSetCurrent = dependente => ({
    type: DEPENDENTES_SET_CURRENT,
    dependente
});

export const DEPENDENTES_SET_CURRENT_SEXO = 'DEPENDENTES_SET_CURRENT_SEXO';
export const dependenteSetCurrentSexo = (field, value) => {
    return {
        type: DEPENDENTES_SET_CURRENT_SEXO,
        field,
        value,
    }
}
export const DEPENDENTES_SET_CURRENT_PARENTESCO = 'DEPENDENTES_SET_CURRENT_PARENTESCO';
export const dependenteSetCurrentParentesco = (field, value) => {
    return {
        type: DEPENDENTES_SET_CURRENT_PARENTESCO,
        field,
        value,
    }
}



export const dependenteSetAtual = (idParceiro, idCliente, idDependente) => async dispatch => {
    if (idDependente === null) {
        var dependente = new DependenteModel().voidConstructor();
        dispatch(dependenteSetCurrent(dependente));
    } else {
        var idToken = await getIdToken();
        request.get(`/parceiro/${idParceiro}/cliente/${idCliente}/dependente/${idDependente}`,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then(resp => {
                var dependente = new DependenteModel().completeConstructor(resp.data.data);
                dispatch(dependenteSetCurrent(dependente));
            }).catch((error) => {
                return error;
            })
    }
}

export const dependenteAtualizar = async (idParceiro, idCliente, idDependente) => {
    var dependente = new DependenteModel().completeConstructor(store.getState().dependente);
    dependente = replaceEspaceFieldsInObject(dependente)
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.patch(`/parceiro/${idParceiro}/cliente/${idCliente}/dependente/${idDependente}`, dependente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then((resp) => {
                if (resp.data.statusOk) {
                    resolve()
                } else {
                    reject(resp.data.message)
                }
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export const dependenteCriar = async (idParceiro, idCliente) => {
    var dependente = new DependenteModel().createConstructor(store.getState().dependente);
    dependente = replaceEspaceFieldsInObject(dependente)
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request
            .post(`/parceiro/${idParceiro}/cliente/${idCliente}/dependente`, dependente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                if (resp.data.statusOk) {
                    resolve()
                } else {
                    reject(resp.data.message)
                }
            }).catch((error) => {
            });
    });
}

export const getDependente = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Dependente e chama o metodo que constrói a Classe de Dependente vazia
            const dependenteModel = new DependenteModel().voidConstructor();
            resolve(dependenteModel);
        } else {
            var currentValue = store.getState().dependentes;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Dependente e chama o metodo que constrói a Classe de Dependente
                    const dependenteModel = new DependenteModel().completeConstructor(item);
                    resolve(dependenteModel);
                }
            });
        }
    });
}

export const dependenteSetAtivo = async (id, ativo) => {
    var idParceiro = id[0];
    var idCliente = id[1];
    var idDependente = id[2];
    var dependente = await getDependente(idDependente);
    return await new Promise(async (resolve, reject) => {
        dependente.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        request
            .patch(`/parceiro/${idParceiro}/cliente/${idCliente}/dependente/${idDependente}`, dependente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                console.log(resp)
                if(resp.data.statusOk){
                    resolve(resp.data.statusOk);
                }else{
                    reject()
                }
            }).catch((error) => {
                console.log(error)
                reject()
            });
    });
}

export const DEPENDENTES_LISTAR = 'DEPENDENTES_LISTAR';
const dependenteListar = (dependente) => ({
    type: DEPENDENTES_LISTAR,
    dependentes: dependente,
});

export const dependentesLista = (idParceiro, idCliente, callback) => {
    return async dispatch => {
        var arrDependente = [];
        var idToken = await getIdToken();
        request.get(`/parceiro/${idParceiro}/clientes/${idCliente}/dependentes`,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(dependente => {
                    var dependenteModel = new DependenteModel().completeConstructor(dependente);
                    arrDependente.push(dependenteModel);
                });
                callback(arrDependente);
                dispatch(dependenteListar(arrDependente));
            }).catch((error) => {
                return error;
            })
    }
}