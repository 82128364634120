import { CLIENTES_LISTAR, CLIENTES_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS } from '../actions';

const INITIAL_STATE = {
	clientes: [],
	parceirosDisponiveis: [
        {
            value: '',
            label: '',
        }
    ],}

export default function associadoListarReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CLIENTES_LISTAR:
			const newState1 = {...state}
			newState1.clientes = action.cliente;
			return newState1;
		case CLIENTES_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS:
			const newState4 = { ...state, parceirosDisponiveis: action.value };
			return newState4;
		default:
			return state;
	}
}