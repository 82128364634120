import {
	ATENDIMENTOS_LISTAR,
	ATENDIMENTOS_TOTAL,
	ATENDIMENTOS_TOTAL_COLLECTION,
	ATENDIMENTOS_TOTAL_ATENDIDOS,
	ATENDIMENTOS_SET_CURRENT_ID,
	ATENDIMENTO_TOTAL_FILA,
	ATENDIMENTO_TEMPOS,
	STATUS_MEDICO,
	ATENDIMENTO_TEMPOS_MEDIOS,
	ATENDIMENTO_DOCUMENTOS,
	ATENDIMENTO_COUNT
} from '../actions';

const INITIAL_STATE = {
	atendimentos: [],
	totalAtendimentosCollection: 0, //TOTAL DE ITENS NA COLLECTION
	totalAtendimentosRealizados: 0, //TOTAL DE ATENDIMENTO REALIZADOS AOS PACIENTES
	atendimento_current_id: 0,
	atendimento_totalFila: 0,
	tempoAtendimento: 0,
	tempoEsperaFila: 0,
	tempoMedioAtendimento: 0,
	tempoMedioEspera: 0,
	tempoMedioTotalServico: 0,
	statusMedico: '',
	total_documentos_anexos: 0,
	atendimento_count: 0,
}

export default function dashboardReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ATENDIMENTOS_LISTAR:
			const newState1 = { ...state, atendimentos: action.atendimentos };
			return newState1;
		case ATENDIMENTOS_TOTAL:
			const newState2 = {
				...state,
				totalAtendimentosCollection: action.totalAtendimentosCollection,
				totalAtendimentosRealizados: action.totalAtendimentosRealizados,
				atendimento_current_id: action.atendimento_current_id,
				statusMedico: action.statusMedico,
			};
			return newState2;
		case ATENDIMENTOS_TOTAL_COLLECTION:
			const newState3 = { ...state, totalAtendimentosCollection: action.totalAtendimentosCollection };
			return newState3;

		case ATENDIMENTOS_TOTAL_ATENDIDOS:
			const newState4 = { ...state, totalAtendimentosRealizados: action.totalAtendimentosRealizados };
			return newState4;

		case ATENDIMENTOS_SET_CURRENT_ID:
			const newState5 = { ...state, atendimento_current_id: action.atendimento_current_id };
			return newState5;

		case ATENDIMENTO_TOTAL_FILA:
			const newState6 = { ...state, atendimento_totalFila: action.atendimento_totalFila };
			return newState6;
		case ATENDIMENTO_TEMPOS:
			const newState7 = { ...state, tempoEsperaFila: action.tempoEsperaFila, tempoAtendimento: action.tempoAtendimento };
			return newState7;
		case ATENDIMENTO_TEMPOS_MEDIOS:
			const newState8 = {
				...state, tempoMedioAtendimento: action.tempoMedioAtendimento,
				tempoMedioEspera: action.tempoMedioEspera,
				tempoMedioTotalServico: action.tempoMedioTotalServico,
			};
			return newState8;
		case ATENDIMENTO_COUNT:
				const newStateCount = {
					...state, atendimento_count: action.atendimento_count,
				}
			return newStateCount;
		case ATENDIMENTO_DOCUMENTOS:
			const newState9 = { ...state, documentos: action.documentos }
			return newState9;
		case STATUS_MEDICO:
			const newState10 =
			{
				...state,
				statusMedico: action.statusMedico
			};
			return newState10;

		default:
			return state;
	}
}
