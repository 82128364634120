import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import PrescricaoModel from '../Model/prescricaoModel.js';
import { getIdToken, request } from '.';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';

//Muda um campo especifico da prescricao atual na redux
export const PRESCRICAO_SET_FIELD = 'PRESCRICAO_SET_FIELD';
export const prescricaoSetField = (field, value) => {
    return {
        type: PRESCRICAO_SET_FIELD,
        field,
        value,
    }
}
//Muda a prescrição atual na redux
export const PRESCRICAO_SET_CURRENT = 'PRESCRICAO_SET_CURRENT';
export const prescricaoSetCurrent = prescricao => ({
    type: PRESCRICAO_SET_CURRENT,
    prescricao
});

//Para não fazer uma nova requisição é chamada essa Function que pega o Antecedente desejado na lista da Redux
export const getPrescricao = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente vazia
            const prescricao = new PrescricaoModel().voidConstructor();
            resolve(prescricao);
        } else {
            var currentValue = store.getState().prescricoes;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    const prescricao = new PrescricaoModel().completeConstructor(item);
                    resolve(prescricao);
                }
            });
        }
    });
}

//Faz a requisição para atualizar a variavel ATIVO da prescricao para True or False;
export const prescricaoSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var prescricao = await getPrescricao(id);
        prescricao.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/prescricaomedica/${id}`,
                prescricao,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//Envia o antecedente para tela de Create ou Update 
export const prescricaoSetAtual = (id) => async dispatch => {
    if (id == null) {
        var prescricao = new PrescricaoModel().voidConstructor();
        dispatch(prescricaoSetCurrent(prescricao));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/prescricaomedica/${id}`,{
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const prescricao = new PrescricaoModel().completeConstructor(resp.data.data);
                dispatch(prescricaoSetCurrent(prescricao));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar a prescricao
export const prescricaoAtualizar = async (idPrescricao) => {
    //Faz a chamada do model prescricao e chama o metodo que constrói a Classe de prescricao para atualizar
    var prescricaoModel = new PrescricaoModel().completeConstructor(store.getState().prescricao);
    prescricaoModel = replaceEspaceFieldsInObject(prescricaoModel)
    prescricaoModel.titulo.trim();
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/prescricaomedica/${prescricaoModel.id}`,
                //Body
                prescricaoModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//Faz a requisição para cadastrar a prescricao
export const prescricaoCriar = async () => {
    //Faz a chamada do model prescricao e chama o metodo que constrói a Classe de prescricao para cadastrar um novo prescricao
    var prescricaoModel = new PrescricaoModel().createConstructor(store.getState().prescricao);
    prescricaoModel = replaceEspaceFieldsInObject(prescricaoModel)
    prescricaoModel.titulo.trim();
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                "/prescricaomedica/",
                //Body
                prescricaoModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//########################LISTAGEM!################################################################################
export const PRESCRICAO_LISTAR = 'PRESCRICAO_LISTAR';
const prescricaoListar = (prescricao) => ({
    type: PRESCRICAO_LISTAR,
    prescricoes: prescricao,
});

//Chama a requisição para listar todos antecedentes
export const prescricoesLista = (callback) => {
    return async dispatch => {
        const arrPrescricoes = [];
        var idToken = await getIdToken();
        request
            .get("/prescricoesmedicas",{
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(prescricao => {
                    //Faz a chamada do model Prescrição e chama o metodo que constrói a Classe de Prescrição
                    let prescricaoModel = new PrescricaoModel().completeConstructor(prescricao)
                    arrPrescricoes.push(prescricaoModel);
                })
                //Envia o array de prescricoes para a redux
                callback(arrPrescricoes);
                return dispatch(prescricaoListar(arrPrescricoes));
            }).catch(error => {
                console.log(error)
            });
    }
};

//Chama a requisição para listar todos antecedentes e converte para encaixar em um elemento de select
export const prescricoesListaToSelect = () => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get("/prescricoesmedicas",
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                resp.data.data.map(prescricao => {
                    //Faz a chamada do model Prescrição e chama o metodo que constrói a Classe de Prescrição
                    let tt = {
                        value: prescricao.id,
                        label: prescricao.titulo,
                        descricao: prescricao.descricao,
                        color: "#00B8D9",
                    }
                    item.push(tt);;
                })
                //Envia o array de prescricoes para a redux
                dispatch(prescricaoListar(item));
            }).catch(error => {
                console.log(error)
            });
    }
}