import firebase from '../config/firebase';
import Cripto from '../utils/cripto'
import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import TecEnfermagemModel from '../Model/tecEnfermagemModel.js';
import { docData } from 'rxfire/firestore';
import { getIdToken, replaceEspaceFieldsInObject, request } from '.';

export const TEC_ENFERMAGEM_SET_FIELD = 'TEC_ENFERMAGEM_SET_FIELD';
export const tecEnfermagemSetField = (field, value) => {
    return {
        type: TEC_ENFERMAGEM_SET_FIELD,
        field,
        value,
    }
}

export const TEC_ENFERMAGEM_SET_CURRENT = 'TEC_ENFERMAGEM_SET_CURRENT';
export const tecEnfermagemSetCurrent = tecEnfermagem => ({
    type: TEC_ENFERMAGEM_SET_CURRENT,
    tecEnfermagem
});

export const tecEnfermagemSetAtual = (id) => async dispatch => {
    if (id == null) {
        var tecEnfermagem = new TecEnfermagemModel().voidConstructor();
        dispatch(tecEnfermagemSetCurrent(tecEnfermagem));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/tecenfermagem/${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then(resp => {
                const tecEnfermagem = new TecEnfermagemModel().completeConstructor(resp.data.data);
                dispatch(tecEnfermagemSetCurrent(tecEnfermagem));
            }).catch(error => {
                console.log(error)
            });
    };
}

export const tecEnfermagemAtualizar = async (id) => {
    var tecEnfermeiro = new TecEnfermagemModel().completeConstructor(store.getState().tecEnfermeiro);
    tecEnfermeiro = replaceEspaceFieldsInObject(tecEnfermeiro)
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.patch(
            `/tecenfermagem/${id}`,
            tecEnfermeiro,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve()
            } else {
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

export const tecEnfermagemCriar = async (uid) => {
    var tecEnfermeiro = new TecEnfermagemModel().createConstructor(store.getState().tecEnfermeiro);
    tecEnfermeiro = replaceEspaceFieldsInObject(tecEnfermeiro)
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(
            `/tecenfermagem`,
            tecEnfermeiro,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                if (resp.data.data === 'The email address is already in use by another account.') {
                    reject(resp.data.data);
                }
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
}

const getTecEnfermagem = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            var tecEnfermeiro = new TecEnfermagemModel().voidConstructor();
            resolve(tecEnfermeiro);
        } else {
            var currentValue = store.getState().tecEnfermeiros;
            let tecEnfermeiro = '';
            currentValue.map((item) => {
                if (item.id === id) {
                    tecEnfermeiro = new TecEnfermagemModel().completeConstructor(item);
                }
            });
            resolve(tecEnfermeiro);
        }
    });
}

export const tecEnfermagemSetAtivo = async (idTecEnfermeiro, ativo) => {
    var tecEnfermagem = await getTecEnfermagem(idTecEnfermeiro);
    return await new Promise(async (resolve, reject) => {
        tecEnfermagem.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/tecenfermagem/${idTecEnfermeiro}`,
                tecEnfermagem, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
};

//########################LISTAGEM!################################################################################
//#################################################################################################################
export const TEC_ENFERMAGEM_LISTAR = 'TEC_ENFERMAGEM_LISTAR';
const tecEnfermagemListar = (tecEnfermeiros) => ({
    type: TEC_ENFERMAGEM_LISTAR,
    tecEnfermeiros: tecEnfermeiros,
});


export const tecEnfermagemLista = (callback) => {
    return async dispatch => {
        const arrTecEnfermeiros = [];
        var idToken = await getIdToken();
        request.get("/tecenfermagens", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(resp => {
                console.log(resp)
                resp.data.data.map(tecEnfermeiro => {
                    let tecEnfermeiroModel = new TecEnfermagemModel().completeConstructor(tecEnfermeiro);
                    arrTecEnfermeiros.push(tecEnfermeiroModel);
                })
                callback(arrTecEnfermeiros);
                dispatch(tecEnfermagemListar(arrTecEnfermeiros));
            })
            .catch((error) => {
                return error;
            });
    };
};

export const userGetDadosEnfermeiro = (callback) => (dispatch) => {
    try {
        var uid = Cripto.DecryptedStorage('uid');
        try {
            var collectionTecEnfermagem = firebase.firestore().collection('tecEnfermagem').doc(uid);
            docData(collectionTecEnfermagem, "uid").subscribe((enfermeiro) => {
                var tecEnfermagemModel = new TecEnfermagemModel().completeConstructor(enfermeiro);
                if(callback !== undefined) {
                    callback(tecEnfermagemModel);
                }
                dispatch(tecEnfermagemSetCurrent(tecEnfermagemModel));
            });
        }
        catch (e) {
            console.log(e)
        }
    }
    catch (e) {
        console.log(e)
    }
}