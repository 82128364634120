// /parceiro/:parceiroId/paciente/:pacienteId

import { getIdToken, request } from "."
import Cripto from "../utils/cripto";

export const BuscaPacienteConsultaComEspecialista = async (parceiroId, cpf) => {
    return await new Promise(async (resolve, reject) => {
        console.log('veio')
        console.log(parceiroId);
        console.log(cpf);
        var idToken = await getIdToken();
        console.log(process.env.REACT_APP_URL_FUNCTIONS_ADDRESS);
        request.get(`/parceiro/${parceiroId}/paciente/${cpf}/agenda`, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then((resp) => {
            console.log(resp)
            if (resp.data.statusOk) {
                resolve(resp.data.data)
                console.log(resp.data.data)
            } else {
                reject(resp.data.message);
            }
        }).catch((error) => {
            console.log(error)
            reject("Falha ao buscar paciente")
        });
    })
}

export const EncaminhaMedicoAtendimento = async (parceiroId, atendimentoId, medicoId, pacienteId) => {
    return await new Promise(async (resolve, reject) => {
        const idToken = await getIdToken();
        request.post(`/parceiro/${parceiroId}/agenda/${atendimentoId}/entrar`, {
            idTecEnfermagem: Cripto.DecryptedStorage('uid'),
            idAtendimento: atendimentoId,
            idPaciente: pacienteId,
            idClinicaDigital: Cripto.DecryptedStorage('idClinicaDigital'),
            fotoTecEnfermagem: Cripto.DecryptedStorage('tecEnfermeiroFoto'),
            nomeTecEnfermagem:  Cripto.DecryptedStorage('tecEnfermeiroNome'),
            colorTecEnfermagem:  Cripto.DecryptedStorage('color'),
        },
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            console.log(resp)
            if (resp.data.statusOk) {
                resolve()
            }else{
                reject()
            }
        }).catch((err) => {
            console.log(err)
            reject()
        })
    })
}