import { AgendaFactory } from '../Model/AgendaFactory.js';
import Cripto from '../utils/cripto.js';
import { getIdToken, request } from './index.js';

export const AGENDA_ATENDIMENTO_SET_FIELD = 'AGENDA_ATENDIMENTO_SET_FIELD';
export const agendaAtendimentoSetField = (field, value) => {
    return {
        type: AGENDA_ATENDIMENTO_SET_FIELD,
        field,
        value,
    }
}
//Muda o agenda atual na redux
export const AGENDA_ATENDIMENTO_SET_CURRENT = 'AGENDA_ATENDIMENTO_SET_CURRENT';
const agendaAtendimentoSetCurrent = agenda => {
    return {
        type: AGENDA_ATENDIMENTO_SET_CURRENT,
        agenda
    }
};

export const agendaAtendimentoSetAtual = (idAgenda, callback) => async dispatch => {
    if (idAgenda == null) {
        var agenda = AgendaFactory.initialAgenda();
        dispatch(agendaAtendimentoSetCurrent(agenda));
    } else {
        Cripto.EncryptedStorage("id_agendamento", idAgenda);
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${Cripto.DecryptedStorage("parceiroId")}/agenda/${idAgenda}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                dispatch(agendaAtendimentoSetCurrent(resp.data.data));
                if(callback !== undefined){
                    callback(resp.data.data)
                }
            }).catch(error => {
                console.log(error)
            });
    }
}