import { ANTECEDENTE_LISTAR	} from '../actions';

const INITIAL_STATE = []

export default function antecedentesListarReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ANTECEDENTE_LISTAR:
            return action.antecedentes;
		default:
			return state;
	}
}