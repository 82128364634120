import {
    AGENDA_SET_FIELD,
    AGENDA_SET_CURRENT
} from '../actions';
import GerarAgendaMedicoModel from '../Model/gerarAgendaMedicoModel';

const INITIAL_STATE_AGENDA = new GerarAgendaMedicoModel().voidConstructor();

export default function agendaReducer(state = INITIAL_STATE_AGENDA, action) {
    switch (action.type) {
         case  AGENDA_SET_CURRENT:
            return action.agenda;
        case AGENDA_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}