import {
    ANTECEDENTE_SET_CURRENT,
    ANTECEDENTES_SET_FIELD
} from '../actions';
import AntecedenteModel from '../Model/antecedenteModel';

const INITIAL_STATE_ANTECEDENTE = new AntecedenteModel().voidConstructor();

export default function antecedenteReducer(state = INITIAL_STATE_ANTECEDENTE, action) {
    switch (action.type) {
        case ANTECEDENTE_SET_CURRENT:
            return action.antecedente;
        case ANTECEDENTES_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

